
import { firestorage } from '../firebase/firestorage'
import { v4 as uuidv4 } from 'uuid';

export const uploadImageAsPromise = (imageFile, path) => {
    // const self = this;
    let imageName = uuidv4()

    return new Promise(function (resolve, reject) {
        let regex = new RegExp('[^.]+$');
        let imageExt = imageFile.name.match(regex);
        var storageRef = firestorage.ref("/"+path+"/"+imageName+"."+imageExt[0]);

        //Upload file
        var task = storageRef.put(imageFile);

        //Update progress bar
        task.on('state_changed',
            function progress(snapshot){
                var percentage = snapshot.bytesTransferred / snapshot.totalBytes * 100;
                return percentage
            },
            function error(err){
                console.error("error")
                reject(err)
            },
            function complete(){
                // var downloadURL = task.snapshot.downloadURL;
                task.snapshot.ref.getDownloadURL()
                    .then(url => {
                        resolve(url)
                    })
                    .catch(err => {
                        console.error('Err URL: ', err)
                        reject(err)
                    })
            }
        );
    });
}