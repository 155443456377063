<template>
  <v-container
    fluid
    class="pa-0 ma-0 white"
  >
    <v-row
      no-gutters
      align="center"
      class="page"
    >
      <v-col
        cols="12"
        sm="5"
        md="8"
        class="d-none d-sm-flex"
        align-self="center"
      >
        <v-img
          src="@/assets/img/sacolas.jpg"
          height="100vh"
        />
      </v-col>
      <v-col
        cols="12"
        sm="7"
        md="4"
      >
        <div class="d-flex flex-column justify-space-between align-center">
          <v-img
            src="@/assets/img/logo.jpeg"
            width="150px"
            class="align-center justify-center pb-3"
          />
        </div>
        <div class="d-flex flex-column justify-space-between align-center text-center">
          <h3 class="pa-3 text-center" v-if="firstName">
            {{firstName}}, seu cadastro foi realizado com sucesso.
          </h3>
          <h3 class="pa-3" v-else>
            Cadastro realizado com sucesso.
          </h3>
          <p class="text-center pa-3" v-if="mother && mother.token">
            Sua senha de cadastro é <span class="font-weight-bold"> {{mother && mother.token ? mother.token : ''}} </span>
          </p>
          <p class="text-center pa-3" v-html="config && config.subscribe && config.subscribe.welcomeMessage ? config.subscribe.welcomeMessage : ''"/>
            <!-- No dia <span class="font-weight-bold">14 de agosto de 2021 às 15:00 </span> será o sorteio, pelo <a href="https://www.facebook.com/lar.ismael.5" target="_blank">Facebook do Lar Ismael</a>, das crianças que receberão as sacolinhas do papai noel
          </p> -->
          <p>
            Fique atento as nossas redes sociais!
          </p>
          <v-row>
            <v-col cols="4">
              <v-btn
                href="https://www.facebook.com/lar.ismael.5/"
                target="_blank"
                icon
              >
                <v-icon
                  large
                  color="blue darken-3"
                >
                  mdi-facebook
                </v-icon>
              </v-btn>
            </v-col>
            <v-col cols="4">
              <v-btn
                href="https://www.instagram.com/lar.ismael/"
                target="_blank"
                icon
              >
                <v-icon
                  large
                  color="grey darken-3"
                >
                  mdi-instagram
                </v-icon>
              </v-btn>
            </v-col>
            <v-col cols="4">
              <v-btn
                :href="whatsAppLink"
                target="_blank"
                icon
              >
                <v-icon
                  large
                  color="green darken-3"
                >
                  mdi-whatsapp
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <!-- <p class="pt-3 text-center">
            Se quiser, adiante o cadastro de seus filhos e você não precisará fazer após o sorteio.
          </p>
          <v-btn
            color="orange darken-3"
            dark
            block
          >
            Adicionar Filhos
          </v-btn> -->
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  import { mapState, mapGetters } from 'vuex'
  export default {
    data: () => ({
      valid: true,
      name: '',
      nameRules: [
        (v) => !!v || 'Ops! Informe seu nome completo.',
        (v) => (v && v.length <= 10) || 'Ops! Informe seu nome completo.',
      ],
      phone: '',
      phoneRules: [
        (v) => !!v || 'Ops! Informe seu telefone.',
        (v) => (v && v.length <= 10) || 'Ops! Informe seu telefone.',
      ],
      email: '',
      emailRules: [
        (v) => !!v || 'E-mail is required',
        (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      select: null,
      items: ['Item 1', 'Item 2', 'Item 3', 'Item 4'],
      checkbox: false,
    }),
    mounted() {
      if(!this.mother) this.$router.push('/')
    },
    computed:{
      ...mapState(['mother', 'config']),
      ...mapGetters(['firstName']),
      whatsAppLink() {
        if(this.firstName) return 'https://api.whatsapp.com/send?phone=5511983093428&text=Ol%C3%A1,%20meu%20nome%20%C3%A9%20' + this.firstName + ',%20fiz%20meu%20cadastro%20mas%20tenho%20uma%20d%C3%BAvida.'
        else return 'https://api.whatsapp.com/send?phone=5511983093428&text=Ol%C3%A1,%20fiz%20meu%20cadastro%20mas%20tenho%20uma%20d%C3%BAvida.'
      }
    },
    methods: {
      validate () {
        this.$refs.form.validate()
      },
      reset () {
        this.$refs.form.reset()
      },
      resetValidation () {
        this.$refs.form.resetValidation()
      },
    },
  }
</script>
<style scoped>
  .page {
    /* background-image: require('../../assets/img/fundo1.jpg'); */
    height: 96vh;
  }
</style>
