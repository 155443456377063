<template>
	<v-form ref="form" v-model="valid" lazy-validation>
		<v-card flat>
			<v-container>

				<v-text-field v-model="title" :rules="titleRules" label="Titulo da Página de Inscrição" required />
				<v-text-field v-model="subtitle" :rules="subtitleRules" label="Subtitulo da Página de Inscrição" required />
				<v-row>
					<v-col cols="12" sm="4">
						<v-switch v-model="activated" inset
							:label="`Inscrições ${activated ? 'Ativadas' : 'Desativada'}`"></v-switch>
					</v-col>
					<v-col cols="12" sm="4">
						<v-text-field v-model="startedDate" :rules="startedDateRules" label="Data de Inicio das Incrições"
							required />
					</v-col>
					<v-col cols="12" sm="12">
						<div class="caption pb-4">Texto de Inscrições Encerradas</div>
						<div>
							<html-editor v-model="finishedMessage" class="pt-0" />

							<!-- <div class="content">
                            <h3>Content</h3>
                            <pre><code>{{ finishedMessage }}</code></pre>
                            </div> -->
						</div>
					</v-col>
				</v-row>
				<div>
					<div class="caption pb-4 pt-6">Regras de Inscrição</div>
					<html-editor v-model="rules" />
				</div>
				<div>
					<div class="caption pb-4 pt-6">Mensagem de Conclusão</div>
					<html-editor v-model="welcomeMessage" :rules="welcomeMessageRules" />
				</div>
				<div>
					<div class="caption pb-4 pt-6">Mensagem de WhatsApp Mães</div>
					<!-- <html-editor v-model="whatsappMessage"/> -->
					<v-textarea outlined name="whatsappMessage" v-model="whatsappMessage"></v-textarea>
				</div>
				<div>
					<div class="caption pb-4 pt-6">Mensagem de WhatsApp para Beneficiarios da Cesta Basica</div>
					<!-- <html-editor v-model="whatsappMessage"/> -->
					<v-textarea outlined name="whatsappMessageCestaBasica" v-model="whatsappMessageCestaBasica"></v-textarea>
				</div>
				<div>
					<div class="caption pb-4 pt-6">Mensagem de WhatsApp Crianças</div>
					<!-- <html-editor v-model="whatsappMessageChild"/> -->
					<v-textarea outlined name="whatsappMessageChild" v-model="whatsappMessageChild"></v-textarea>
				</div>
				<v-card-actions>
					<v-spacer />
					<v-btn :loading="loading" color="primary" @click.prevent="validate"
						:disabled="!valid || loading">Salvar</v-btn>
				</v-card-actions>
			</v-container>
		</v-card>
		<v-snackbar v-model="snackbar" :multi-line="snackbarMultiLine" :color="snackbarColor" timeout=6000 :top="true">
			{{ snackbarText }}

			<template v-slot:action="{ attrs }">
				<v-btn text v-bind="attrs" @click="snackbar = false">
					<v-icon dark>mdi-close</v-icon>
				</v-btn>
			</template>
		</v-snackbar>
	</v-form>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import HtmlEditor from '../../editor/HtmlEditor.vue'
export default {
	components: { HtmlEditor },
	data() {
		return {
			valid: true,
			title: '',
			titleRules: [
				(v) => !!v || 'Ops! Informe o titulo da página de inscrição.',
				(v) => (v && v.length >= 10) || 'Ops! Informe o titulo da página de inscrição.',
			],
			subtitle: '',
			subtitleRules: [
				(v) => !!v || 'Ops! Informe seu nome completo.',
				(v) => (v && v.length >= 10) || 'Ops! o subtitulo deve conter mais que 10 caracteres.',
			],
			activated: false,
			startedDate: '',
			startedDateRules: [
				(v) => !!v || 'Ops! Informe a data de inicio das inscrições.',
				(v) => (v && v.length >= 10) || 'Ops! Informe a data e hora AAAA-MM-DD HH:MM',
			],
			finishedMessage: '',
			finishedMessageRules: [
				(v) => !!v || 'Ops! Informe o texto apresentado ao encerrar inscrições.',
				(v) => (v && v.length >= 10) || 'Ops! Informe o texto apresentado ao encerrar inscrições.',
			],
			rules: '',
			welcomeMessage: '',
			welcomeMessageRules: [
				(v) => !!v || 'Ops! Informe o texto apresentado ao concluir as inscrições.',
				(v) => (v && v.length >= 10) || 'Ops! Informe o texto apresentado ao concluir as inscrições.',
			],
			whatsappMessage: '',
			whatsappMessageRules: [
				(v) => !!v || 'Ops! Informe a mensagem de whatsapp que será enviada as mães.',
				(v) => (v && v.length >= 10) || 'Ops! Informe a mensagem de whatsapp que será enviada as mães.',
			],
			whatsappMessageCestaBasica: '',
			whatsappMessageCestaBasicaRules: [
				(v) => !!v || 'Ops! Informe a mensagem de whatsapp que será enviada os beneficiarios da cesta básica.',
				(v) => (v && v.length >= 10) || 'Ops! Informe a mensagem de whatsapp que será enviada os beneficiarios da cesta básica.',
			],
			whatsappMessageChild: null,
			loading: false,
			snackbar: false,
			snackbarColor: 'green',
			snackbarText: '',
			snackbarMultiLine: true,
		}
	},
	computed: {
		...mapState(['config'])
	},
	async mounted() {
		if (this.config && this.config.subscribe) {
			this.title = this.config.subscribe.title
			this.subtitle = this.config.subscribe.subtitle
			this.activated = this.config.subscribe.activated
			this.startedDate = this.config.subscribe.startedDate
			this.finishedMessage = this.config.subscribe.finishedMessage
			this.rules = this.config.subscribe.rules
			this.welcomeMessage = this.config.subscribe.welcomeMessage
			this.whatsappMessage = decodeURI(this.config.subscribe.whatsappMessage) || ''
			this.whatsappMessageCestaBasica = decodeURI(this.config.subscribe.whatsappMessageCestaBasica) || ''
			this.whatsappMessageChild = this.config.subscribe.whatsappMessageChild ? decodeURI(this.config.subscribe.whatsappMessageChild) : null
		} else {
			await this.getConfig()
				.then(config => {
					this.title = config.subscribe.title
					this.subtitle = config.subscribe.subtitle
					this.activated = config.subscribe.activated
					this.startedDate = config.subscribe.startedDate
					this.finishedMessage = config.subscribe.finishedMessage
					this.rules = config.subscribe.rules
					this.welcomeMessage = config.subscribe.welcomeMessage
					this.whatsappMessage = decodeURI(config.subscribe.whatsappMessage) || ''
					this.whatsappMessageCestaBasica = decodeURI(config.subscribe.whatsappMessageCestaBasica) || ''
					this.whatsappMessageChild = this.config.subscribe.whatsappMessageChild ? decodeURI(this.config.subscribe.whatsappMessageChild) : null
				})
		}
	},
	methods: {
		...mapActions(['getConfig', 'updateConfig']),
		async validate() {
			if (!this.$refs.form.validate()) {
				this.error = 'Informe os dados solicitados'
				this.dialog = false
				return
			}
			this.loading = true
			let params = {
				id: 'subscribe',
				data: {
					title: this.title,
					subtitle: this.subtitle,
					activated: this.activated,
					startedDate: this.startedDate,
					finishedMessage: this.finishedMessage,
					rules: this.rules,
					welcomeMessage: this.welcomeMessage,
					whatsappMessage: encodeURI(this.whatsappMessage),
					whatsappMessageCestaBasica: encodeURI(this.whatsappMessageCestaBasica),
					whatsappMessageChild: this.whatsappMessageChild && this.whatsappMessageChild !== '' ? encodeURI(this.whatsappMessageChild) : null
				}
			}
			this.updateConfig(params)
				.then(() => {
					this.loading = false
					this.showSnackBar('Configurações atualizadas com sucesso. ', 'green')
					location.reload(true);
				})
				.catch(err => {
					this.showSnackBar('Erro ao gravar as configurações. ', 'red')
					console.error(err)
					this.loading = false
				})
		},
		showSnackBar(text, color) {
			this.snackbarText = text,
				this.snackbarColor = color
			this.snackbar = true
		},
	}
}
</script>
<style lang="scss" scoped>
::v-deep() {

	/* Basic editor styles */
	.ProseMirror {
		>*+* {
			margin-top: 0.75em;
		}

		code {
			background-color: rgba(#616161, 0.1);
			color: #616161;
		}
	}

	.content {
		padding: 1rem 0 0;

		h3 {
			margin: 1rem 0 0.5rem;
		}

		pre {
			border-radius: 5px;
			color: #333;
		}

		code {
			display: block;
			white-space: pre-wrap;
			font-size: 0.8rem;
			padding: 0.75rem 1rem;
			background-color: #e9ecef;
			color: #495057;
		}
	}
}
</style>