<template>
  <v-container
    fluid
    class="pa-0 ma-0 white"
    v-if="!loading"
  >
    <v-row
      no-gutters
      align="center"
      class="page pa-0 ma-0"
    >
      <v-col
        cols="12"
        sm="5"
        md="8"
        class="d-none d-sm-flex"
        align-self="center"
      >
        <v-img
          src="@/assets/img/lar-ismael-voluntarios.jpeg"
          height="100vh"
        />
      </v-col>
      <v-col
        cols="12"
        sm="7"
        md="4"
      >
        <div class="d-flex flex-column justify-space-between align-center">
          <v-img
            src="@/assets/img/logo.jpeg"
            width="150px"
            class="align-center justify-center"
          />
        </div>
        <div class="d-flex flex-column justify-space-between align-center pr-6 pl-6">
          <h4>{{ config && config.subscribe && config.subscribe.title ? config.subscribe.title : 'Festa de Natal das Crianças' }}</h4>
          <h5>Cadastro dos filhos</h5>
        </div>
        <div v-if="!inactivatedSubscribe" class="pt-6 text-center" v-html="config && config.subscribe && config.subscribe.finishedMessage ? config.subscribe.finishedMessage : 'Cadastro encerrado!'"/>
        <mother-form v-else />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  import { mapState, mapActions, // mapGetters 
    } from 'vuex'
  export default {
    data: () => ({
      activated: false,
      labels: {
        days: 'Dias',
        hours: 'Horas',
        minutes: 'Minutos',
        seconds: 'Segundos',
      },
      // draw_date: '2021-08-02 19:58:00'
      draw_date: '2022-08-13 15:00:00',
      inactivatedSubscribe: true
    }),
    computed: {
      ...mapState(['config']),
    //   ...mapGetters(['inactivatedSubscribe'])
    },
    async created() {
      this.loading = true
      if(!this.config) {
        await this.getConfig()
          .then(conf => {
            if(!conf) return this.$router.push('/admin/login')
            else if(conf && conf.subscribe && conf.subscribe.startedDate) {
              this.draw_date = conf.subscribe.startedDate
            }
            this.loading = false
          })
          .catch(err => {
            this.loading = false
            console.error('err: ', err)
            this.$router.push('/admin/login')
          })
      } else {
        this.loading = false
      }
      if(this.config.subscribe && this.config.subscribe.startedDate) this.draw_date = this.config.subscribe.startedDate

      let today = new Date()
      let draw_date = new Date(this.config.subscribe.startedDate.replace(' ', 'T'))
      if(today.getTime() > draw_date.getTime()) this.activated = true
      
    },
    components: {
      MotherForm: () => import('../../components/children/MotherForm.vue'),
    },
    methods: {
      ...mapActions(['getConfig']),
      timeElapsedHandler() {
        this.activated = true
      }
    },
  }
</script>
<style scoped>
  .page {
    /* background-image: require('../../assets/img/fundo1.jpg'); */
    height: 100vh;
  }
</style>
