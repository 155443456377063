
const template = async (childrens) => {
    let htags = await getTags(childrens)

    let doc = {
        // a string or { width: number, height: number }
        // pageSize: 'LETTER',
        pageSize: {
            width: size(215.90), //215.90cm
            height: size(279.40) //279.40
        },

        // by default we use portrait, you can change it to landscape if you wish
        // pageOrientation: 'landscape',

        // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
        // pageMargins: [ 14.50, 12.70, 14.5, 12.70 ],
        pageMargins: [ size(1.5), size(1) ],
        content: [
            {
                table: {
                    // headers are automatically repeated if the table spans over multiple pages
                    // you can declare how many rows should be treated as headers
                    // headerRows: 0,
                    heights: size(137.3),
                    widths: [ size(103), size(103)], //
                    // heights: [size(134.9), size(134.9)],
                    // body: [
                    //     ['row 1', 'column B'],
                    //     ['row 2', 'column B'],
                    //     ['row 3', 'column B'],
                    //     ['row 5', 'column B'],
                    //     ['row 6', 'column B'],
                    //     ['row 7', 'column B'],
                    // ],
                    body: htags || [],
                    // pageBreak: 'after'
                },
                
            },
            // {text: htags},
            // {text: 'Text on Landscape 2', pageOrientation: 'portrait', pageBreak: 'after'},
            // {text: 'Text on Portrait 2'},
        ],
        defaultStyle: {
            // fontSize: 15,
            // bold: true,
            // lineHeight: size(7.74)
            alignment: "center",
        },
        styles: {
            controle: {
              fontSize: 18,
              bold: true,
              // margin: [left, top, right, bottom]
              margin: [ 0, 3, 0, 0 ],
              alignment: 'right',
              color: "orange",
            },
            crianca: {
                fontSize: 16,
                bold: true,
            },
            mae: {
                fontSize: 16,
            },
            dados: {
                fontSize: 13,
            },
            dados2: {
                // margin: [left, top, right, bottom]
                margin: [ 2, 2, 2, 2 ],
                fontSize: 11,
            },
            local: {
                margin: [ 12, 2, 8, 2 ],
                alignment: "right",
                color: "blue",
                fontSize: 13,
                bold: true
            },
            devolucao: {
                fontSize: 13,
                bold: true,
                color: 'red'
            }
        },
    }
    return doc
}

module.exports = {
    template
}

const TRANSFORM = 2.8346666667;

function size(cm) {
  return TRANSFORM * cm;
}

function getTags(childrens) {
    return new Promise((resolve) => {
        let tags = []
        let t = []
        let cols = 0
        for(let x = 0; x < childrens.length; x++) {
            let list = childrens[x]
            for(let y = 0; y < list.length; y++) {
                cols = cols + 1
                let text = [
                    {
                        text: {
                            text: list[y].mother.order || 'erro'
                        },
                        style: 'controle'
                    },
                    {
                        text: {
                            text: list[y].name + '\n\n'
                        },
                        style: 'crianca'
                    },
                    {
                        text: [
                            'Idade: ',
                            { text: list[y].age + ' ano(s)', bold: true }
                        ],
                        style: 'dados'
                    },
                    {
                        text: [
                            'Roupa: ',
                            { text: list[y].clothes, bold: true },
                            ' | Sapato: ',
                            { text: list[y].shoes, bold: true },
                            ' (não é chinelo) '
                        ],
                        style: 'dados'
                    }, 
                    {
                        text: [
                            (list[y].gender === 'F' ? ' calcinha ' : ' cueca '),
                            ' | meias | BRINQUEDO para a idade'
                        ],
                        style: 'dados'
                    }, 
                    {
                        text: {
                            text: 'Mais o que você puder/quiser colocar como roupas, sandália ou chinelo, material escolar, produto de higiene pessoal, docinhos, panetone, cartão de natal e muito amor!'
                        },
                        style: 'dados2'
                    }, 
                    {
                        text: [
                            '\nMãe: ',
                            { text: list[y].mother.name, color: 'red', bold: true }
                        ],
                        style: 'mae'
                    },
                    {
                        text: {
                            text: 'Local: ' + list[y].mother.pickUpPlace
                        },
                        style: 'local'
                    },
                    {
                        text: {
                            text: '\nAcesse a cartinha do seu afilhado em larismael.org/c/' + list[y].code + ' ou pelo QRCode abaixo'
                        },
                        style: 'dados2'
                    },
                    { qr: 'https://larismael.org/c/' + list[y].code, fit: '73' },
                    {
                        text: {
                            text: '\n\nDEVOLVER ATÉ O DIA 14 DE NOVEMBRO\n'
                        },
                        style: 'devolucao',
                        // pageBreak: ((contador/4) > 0 && Number.isInteger(contador/4) ? 'after' : 'none')
                    }                    
                ]
                t.push(text)
                if(cols > 1) {
                    tags.push(t)
                    t = []
                    cols = 0
                }
                if((x+1) === 10) {
                    resolve(tags)
                }
            }
        }
        
    })
    
}
