<template>
    <v-navigation-drawer
      v-model="children_dialog"
      fixed
      right
      light
      :width="width"
      lazy
    >
        <v-card flat>
          <v-icon @click.stop="cancel" class="ma-3">mdi-close</v-icon>
            <v-container class="pa-6 pt-2">
              <h2>Cadastrar Filho</h2>
              <v-form
                  ref="form"
                  v-model="valid"
                  lazy-validation
              >

                <v-col cols="12" sm="12">
                  Sexo: 
                  <v-btn :color="gender ==='M' ? 'grey' : 'pink'" dark class="ma-3" :outlined="!gender || gender ==='M'" @click="setGender('F')">
                    <v-icon left>mdi-face-woman-outline</v-icon> 
                    Menina
                  </v-btn>
                  <v-btn :color="gender ==='F' ? 'grey' : 'indigo'" dark  :outlined="!gender || gender ==='F'" @click="setGender('M')">
                    <v-icon left>mdi-account-cowboy-hat</v-icon>
                    Menino
                  </v-btn>
                  <div class="red--text" v-if="genderRequired">Informe o sexo da criança</div>
                </v-col>

                <v-col cols="12" sm="12" v-if="id">ID: {{ id }} </v-col>
                

                <v-col cols="12" v-if="gender">

                  <v-text-field
                    v-model="document"
                    :rules="documentRules"
                    label="Nº do RG, CPF ou Certidão de Nascimento"
                    outlined
                    placeholder="Sem traços e sem pontos"
                    required
                    rounded
                    @change="findChildren(document)"
                    loading="loadingSearch"
                    :disabled="!this.new"
                    append-icon="mdi-magnify"
                    @click:append="findMother(document)"
                  />

                  <v-text-field
                    v-model="name"
                    :rules="nameRules"
                    label="Nome do Filho"
                    outlined
                    placeholder=""
                    required
                    rounded
                    @change="clearError"
                  />

                  
                  <v-text-field
                    v-model="birthday"
                    :rules="birthdayRules"
                    label="Data de Nascimento"
                    outlined
                    placeholder=""
                    required
                    rounded
                    v-mask="'##/##/####'"
                    @change="clearError"
                    :hint="age ? age + ' ano(s)':''"
                  />
                  <v-combobox
                    v-model="clothes"
                    :rules="clothesRules"
                    :items="clothes_items"
                    :return-object="false"
                    outlined
                    placeholder="Digite ou escolha na lista"
                    required
                    rounded
                    color="blue-grey lighten-2"
                    label="Tamanho da Roupa"
                    item-value="value"
                  >
                    <template v-slot:selection="data">
                        {{ data.item.value ? data.item.value : data.item }}
                    </template>
                    <template v-slot:item="data">
                      <template v-if="typeof data.item !== 'object'">
                        <v-list-item-content v-text="data.item"></v-list-item-content>
                      </template>
                      <template v-else>
                        <v-list-item-content>
                          <v-list-item-title v-html="data.item.value"></v-list-item-title>
                          <v-list-item-subtitle v-html="data.item.text"></v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </template>
                  </v-combobox>

                  <v-autocomplete
                    v-model="shoes"
                    :items="shoes_items"
                    outlined
                    placeholder="Digite ou escolha na lista"
                    required
                    rounded
                    color="blue-grey lighten-2"
                    label="Tamanho do calçado"
                    item-text="text"
                    item-value="value"
                    :rules="shoesRules"
                  >
                    <template v-slot:selection="data">
                        {{ data.item.value }}
                    </template>
                    <template v-slot:item="data">
                      <template v-if="typeof data.item !== 'object'">
                        <v-list-item-content v-text="data.item"></v-list-item-content>
                      </template>
                      <template v-else>
                        <v-list-item-content>
                          <v-list-item-title v-html="data.item.value"></v-list-item-title>
                          <!-- <v-list-item-subtitle v-html="data.item.text"></v-list-item-subtitle> -->
                        </v-list-item-content>
                      </template>
                    </template>
                  </v-autocomplete>
                  <!--
                  <v-combobox
                    v-model="status"
                    v-if="isAdmin"
                    :items="status_items"
                    outlined
                    placeholder="Escolha o Status da Criança"
                    required
                    rounded
                    color="blue-grey lighten-2"
                    label="Status"
                  >
                  </v-combobox>
                  -->
                  <!-- <div v-if="!documentUrl || documentEdit" class="pb-2 font-weight-bold text-center">Tire uma foto do documento do seu filho no lado que comprove a data de nascimento.</div>
                  <div v-else class="pb-2 font-weight-bold text-center pt-3 mt-3 mb-1">Documento</div>
                  <v-file-input
                    v-model="documentImage"
                    label="TIRAR FOTO DO DOCUMENTO"
                    :rules="rulesDocumentImage"
                    prepend-icon="mdi-camera"
                    hint="lado que comprove a data de nascimento"
                    color="blue"
                    class="pb-4"
                    accept="image/*"
                    v-if="!documentUrl || documentEdit"
                  ></v-file-input>
                  <v-img v-else
                   :src="documentUrl"
                   :lazy-src="documentUrl"
                   max-width="500"
                   max-height="300"
                  >
                      <v-row
                        class="fill-height ma-0 pa-3 pb-4"
                        align="end"
                        justify="end"
                      >
                        <v-btn
                          class="mx-2"
                          fab
                          dark
                          color="black"
                          @click="childrendoc_dialog = true"
                        >
                          <v-icon dark>
                            mdi-magnify-plus
                          </v-icon>
                        </v-btn>
                        <v-btn
                          class="mx-2"
                          fab
                          dark
                          color="black"
                          @click="documentEdit = true"
                        >
                          <v-icon dark>
                            mdi-pencil
                          </v-icon>
                        </v-btn>
                      </v-row>
                  </v-img> -->
                  <v-row
                    class="fill-height ma-0 pa-3 pb-4"
                    align="center"
                    justify="center"
                    v-if="isAdmin"
                  >
                    <v-btn small color="red" dark @click.prevent="saveStatus('desativado')">Reprovado</v-btn>
                    <v-spacer/>
                    <v-btn small color="blue" dark @click.prevent="saveStatus('analise')">Analise</v-btn>
                    <v-spacer/>
                    <v-btn small color="green" dark @click.prevent="saveStatus('aprovado')">Aprovado</v-btn>
                  </v-row>
                  <div v-if="!photoUrl || photoEdit" class="pb-2 font-weight-bold text-center">Se desejar, coloque uma foto {{this.gender === 'M' ? 'do seu filho' : 'da sua filha'}} para que o Padrinho passa {{this.gender === 'M' ? 'conhece-lo' : 'conhece-la'}} melhor.</div>
                  <div v-else class="pb-2 font-weight-bold text-center pt-3 mt-3 mb-1">Foto</div>
                  <v-file-input
                    v-model="photo"
                    :label="'TIRAR FOTO ' + (this.gender === 'M' ? 'DO SEU FILHO' : 'DA SUA FILHA') + ' (opcional)'"
                    prepend-icon="mdi-camera"
                    hint="lado que comprove a data de nascimento"
                    color="blue"
                    class="pb-4"
                    accept="image/*"
                    v-if="!photoUrl || photoEdit"
                  ></v-file-input>
                  <v-img v-else
                   :src="photoUrl"
                   :lazy-src="photoUrl"
                   max-width="500"
                   max-height="300"
                   class="mb-3"
                  >
                    <!-- <template v-slot:placeholder> -->
                      <v-row
                        class="fill-height ma-0 pa-3 pb-4"
                        align="end"
                        justify="end"
                      >
                        <v-btn
                          class="mx-2"
                          fab
                          dark
                          color="black"
                          @click="photoEdit = true"
                        >
                          <v-icon dark>
                            mdi-pencil
                          </v-icon>
                        </v-btn>
                      </v-row>
                    <!-- </template> -->
                  </v-img>
                  <v-row v-if="error || !valid">
                    <v-col cols="12">
                        <div class="red--text text-center aling-center">
                        {{ error }}
                        </div>
                    </v-col>
                  </v-row>
                  <v-btn
                    :loading="loading"
                    color="green"
                    class="mr-4 mb-3"
                    block
                    rounded
                    :dark="!error && valid"
                    v-if="mother"
                    :href="'https://api.whatsapp.com/send?phone=' + mother.phone + '&text=' + setWhatsAppMessage(this.child)" target="_blank"
                  >
                    <v-icon left small color="white">mdi-whatsapp</v-icon>
                      Enviar Mensagem
                  </v-btn>
                  <v-btn
                    :loading="loading"
                    color="orange darken-3"
                    :dark="!error && valid"
                    class="mr-4 mb-3"
                    block
                    rounded
                    @click="validate"
                    v-if="this.new"
                  >
                      SALVAR
                  </v-btn>
                  <v-btn
                    v-else
                    :loading="loading"
                    color="orange darken-3"
                    :dark="!error && valid"
                    class="mr-4 mb-3"
                    block
                    rounded
                    @click="validateEdit"
                    
                  >
                      SALVAR
                  </v-btn>
                </v-col>
                <v-col cols="12" sm="12">
                  <v-btn
                    :disabled="!valid || loading"
                    :loading="loading"
                    color="red darken-3"
                    :dark="!error && valid"
                    class="mr-4"
                    block
                    rounded
                    @click="cancel"
                  >
                    CANCELAR
                  </v-btn>
                </v-col>
              </v-form>
            </v-container>
        </v-card>
        <v-navigation-drawer
          v-model="childrendoc_dialog"
          fixed
          right
          light
          :width="width_imagezom"
          lazy
        >
          <v-card flat>
            <v-container>
              <v-icon @click.stop="childrendoc_dialog = false" class="ma-3">mdi-close</v-icon>
              <v-text-field
                v-model="birthday2"
                :rules="birthdayRules"
                label="Data de Nascimento"
                outlined
                placeholder=""
                required
                rounded
                disabled
                v-mask="'##/##/####'"
                @change="clearError"
                :hint="age ? age + ' ano(s)':''"
              />
              <v-row
                class="fill-height ma-0 pa-3 pb-4"
                align="center"
                justify="center"
              >
                <v-btn small color="red" dark @click.prevent="saveStatus('desativado')">Reprovado</v-btn>
                <v-spacer/>
                <v-btn small color="blue" dark @click.prevent="saveStatus('analise')">Analise</v-btn>
                <v-spacer/>
                <v-btn small color="green" dark @click.prevent="saveStatus('aprovado')">Aprovado</v-btn>
              </v-row>
              <v-img
                :src="documentUrl"
                :lazy-src="documentUrl"
                max-width="100%"
                max-height="100%"
              />
            </v-container>
          </v-card>
        </v-navigation-drawer>
        <v-snackbar
            v-model="snackbar"
            top
            :multi-line="true"
            :color="snackbar_color"
            :timeout=4000
          >
            {{ snackbar_message }}
          </v-snackbar>
    </v-navigation-drawer>
</template>
<script>
  import { bus } from '../../main';
  import moment from 'moment'
  // import { setMother, isMotherDuplicated } from '../../services/mothers'
  import { isChildDuplicated } from '../../services/children'
  import { mapActions, mapState } from 'vuex'
  // import { firestorage } from '../../firebase/firestorage'
  import { uploadImageAsPromise } from '../../services/upload'

  export default {
    name: 'SubscribeForm',
    data () {
      return {
        valid: true,
        new: true,
        id: null,
        children_dialog: false,
        name: '',
        nameRules: [
          (v) => !!v || 'Ops! Informe seu nome completo.',
          (v) => (v && v.length >= 10) || 'Ops! Informe seu nome completo.',
        ],
        mother_id: null,
        document: '',
        document_old: null,
        documentRules: [
          (v) => !!v || 'Ops! Informe seu RG.',
          (v) => (v && v.length >= 6) || 'Ops! Informe seu RG.',
        ],
        documentImage: null,
        documentEdit: null,
        photo: null,
        documentUrl: null,
        photoUrl: null,
        photoEdit: null,
        rulesDocumentImage: [
          (v) => !!v || 'Ops! Tire a foto do documento.',
          (v) => (v && v.size < 10000000) || 'A Imagem precisa ter menos de 10 MB',
        ],
        birthday: null,
        birthdayRules: [
          (v) => !!v || 'Ops! Informe a Data de Nascimento.',
          (v) => (v && v.length >= 10) || 'Ops! Informe a Data de Nascimento.',
          (v) => this.calculateAge(v) <= 10 || 'Ops! Criança possui mais de 10 anos em 31/12/2022.',
          (v) => this.validDate(v) || 'Ops! Data Inválida.'
        ],
        age: null,
        gender: null,
        genderRequired: false,
        clothes: null,
        clothesRules: [
          (v) => !!v || 'Ops! Informe o tamanho da roupa do seu filho.',
        ],
        shoes: null,
        shoesRules: [
          (v) => !!v || 'Ops! Informe o tamanho do calçado da criança',
        ],
        error: null,
        status: 'cadastro',
        status_items: ['cadastro', 'analise', 'aprovado', 'desativado'],
        loading: false,
        clothes_items: [
          { value:'PP',text: 'Recém-nascido' },
          { value:'P',text: 'Até 3 meses' },
          { value:'M',text: '3 a 6 meses' },
          { value:'G',text: '6 a 9 meses' },
          { value:'GG',text: '9 a 12 meses' },
          { value:'1',text: '' },
          { value:'2',text: '' },
          { value:'3',text: '' },
          { value:'4',text: '' },
          { value:'6',text: '' },
          { value:'8',text: '' },
          { value:'10',text: '' },
          { value:'12',text: '' },
          { value:'14',text: '' },
          { value:'P (Adulto)',text: 'Adulto' },
          { value:'M (Adulto)',text: 'Adulto' },
          { value:'G (Adulto)',text: 'Adulto' },
          { value:'GG (Adulto)',text: 'Adulto' },
        ],
        shoes_items: [
          { text: '', value:13 },
          { text: '', value:14 },
          { text: '', value:15 },
          { text: '', value:16 },
          { text: '', value:17 },
          { text: '', value:18 },
          { text: '', value:19 },
          { text: '', value:20 },
          { text: '', value:21 },
          { text: '', value:22 },
          { text: '', value:23 },
          { text: '', value:24 },
          { text: '', value:25 },
          { text: '', value:26 },
          { text: '', value:27 },
          { text: '', value:28 },
          { text: '', value:29 },
          { text: '', value:30 },
          { text: '', value:31 },
          { text: '', value:32 },
          { text: '', value:33 },
          { text: '', value:34 },
          { text: '', value:35 },
          { text: '', value:36 },
          { text: '', value:37 },
          { text: '', value:38 },
          { text: '', value:39 },
          { text: '', value:40 },
          { text: '', value:41 },
          { text: '', value:42 },
          { text: '', value:43 },
        ],
        uploadValue: 0,
        snackbar: false,
        snackbar_color: 'green',
        snackbar_message: '',
        success_message: null,
        isAdmin: false,
        childrendoc_dialog: false,
        birthday2: null,
        child: null,
        mother: null,
        year: [],
      }
    },
    created() {
        this.documentUrl = null
        this.photoUrl = null
        bus.$on('openChildrenDialog', (params) => {
          console.log('openChildrenDialog', params)
          console.log('documentEdit', this.documentEdit)
          console.log('documentUrl', this.documentUrl)
            this.documentEdit = false
            this.photoEdit = false
            this.children_dialog = params.dialog;
            this.index = params.index;
            this.mother_id = params.mother_id
            this.isAdmin = params.isAdmin || false
            if(params.child) {
              this.child = params.child
              this.gender = params.child.gender
              this.name = params.child.name
              this.document = params.child.document
              this.document_old = params.child.document
              this.birthday = params.child.birthday
              // this.age = params.child.age
              this.age = this.calculateAge(params.child.birthday)
              this.clothes = params.child.clothes
              this.shoes = params.child.shoes
              this.mother_id = params.child.mother_id
              this.documentUrl = params.child.documentUrl
              this.photoUrl = params.child.photoUrl
              this.id = params.child.id
              this.year = params.child.year
              this.new = false
              this.mother = params.child.mother
              if(params.child.status) this.status = params.child.status
            } else {
              this.gender = null
              this.name = null
              this.document = null
              this.document_old = null
              this.birthday = null
              this.age = null
              this.clothes = null
              this.shoes = null
              this.documentUrl = null
              this.photoUrl = null
              this.id = null
              this.new = true
              this.valid = true
              this.documentImage = null
              this.phone = null
              this.year = []
              
            }
        })
    },
    computed: {
      ...mapState(['config','defaultYearParty', 'selectedYear']),
      // eslint-disable-next-line vue/return-in-computed-property
      width () {
        switch (this.$vuetify.breakpoint.name) {
          case 'xs': return '100%'
          case 'sm': return 500
          case 'md': return 500
          case 'lg': return 500
          case 'xl': return 500
        }
      },
      // eslint-disable-next-line vue/return-in-computed-property
      width_imagezom () {
        switch (this.$vuetify.breakpoint.name) {
          case 'xs': return '150%'
          case 'sm': return '150%'
          case 'md': return '150%'
          case 'lg': return '150%'
          case 'xl': return '150%'
        }
      },
    },
    watch: {
      birthday(val) {
        this.birthday2 = val
      }
    },
    methods: {
      ...mapActions('children', ['addChild', 'updateChild']),
      setGender(gender) {
        this.gender = gender
      },
      async validate () {
        if (!this.$refs.form.validate()) {
          this.error = 'Informe os dados solicitados'
          // this.dialog = false
          return
        }
        if(!this.mother_id) {
          this.error = 'Ops! Erro, mãe não informada'
          // this.dialog = false
          this.loading = false
          return
        }
        this.loading = true
        if(await isChildDuplicated(this.document)) {
          this.error = 'Ops! Criança já está inscrita'
          // this.dialog = false
          this.loading = false
          return
        }

        let photo_url = null
        if(this.photo) {
          await uploadImageAsPromise(this.photo, 'children/photos')
            .then(url => {
              photo_url = url
            })
            .catch(err => {
              console.error('Erro ao adicionar photo da criança. ', err.message)
              this.error = 'Erro ao adicionar photo da criança'
              this.loading = false
              return
            })
        }
        let document_url = null
        if(this.documentImage) {
          await uploadImageAsPromise(this.documentImage, 'children/documents')
            .then(url => {
              document_url = url
            })
            .catch(err => {
              console.error('Erro ao adicionar document da criança. ', err.message)
              this.error = 'Erro ao adicionar document da criança'
              this.loading = false
              return
            })
        }

        const child = {
          created_at: new Date(),
          updated_at: new Date(),
          status: this.status,
          name: this.name,
          document: this.document,
          documentFormatted: this.document.replace(/[\W]/g,'').toUpperCase(),
          birthday: this.birthday,
          // age: this.age,
          age: this.calculateAge(this.birthday),
          clothes: this.clothes,
          shoes: this.shoes,
          // documentUrl: url,
          documentUrl: document_url,
          type: 'child',
          gender: this.gender,
          yearParty: (new Date()).getFullYear(),
          mother_id: this.mother_id,
        }

        if(!this.year.includes(this.defaultYearParty)) {
            this.year.push(this.defaultYearParty)
            child.year = this.year
        }

        if(photo_url) child.photoUrl = photo_url
        await this.addChild(child)
          .then(async () => {
            this.loading = false
            this.showSnackbar('Cadastro realizado com sucesso!')
            this.success_message = 'Cadastro realizado com sucesso!'
            this.children_dialog = false
          })
          .catch(err => {
            console.error(err)
            this.loading = false
            this.error = 'Erro ao realizar o cadastro'
            this.showSnackbar('Erro ao realizar o cadastro', 'red')
          })
        
      },
      saveStatus(status) {
        const child = {
          updated_at: new Date(),
          status: status,
          id: this.id
        }
        this.status = status
        this.updateChild(child)
          .then(async () => {
            this.showSnackbar('Cadastro '+status+' com sucesso!', null)
            this.success_message = 'Cadastro '+status+' com sucesso!'
            this.loading = false
            // this.children_dialog = false
          })
          .catch(err => {
            console.error(err)
            this.loading = false
            this.error = 'Erro ao atualizar o status do cadastro'
            this.showSnackbar('Erro ao atualizar o status do cadastro!', 'red')
          })
      },
      async validateEdit () {
        if (!this.$refs.form.validate()) {
          this.error = 'Informe os dados solicitados'
          // this.dialog = false
          return
        }
        if(!this.mother_id) {
          this.error = 'Ops! Erro, mãe não informada'
          // this.dialog = false
          this.loading = false
          return
        }
        this.loading = true
        if(this.document_old !== this.document && await isChildDuplicated(this.document)) {
          this.error = 'Ops! Criança já está inscrita 2'
          // this.dialog = false
          this.loading = false
          return
        }

        let photo_url = null
        if(this.photo) {
          await uploadImageAsPromise(this.photo, 'children/photos')
            .then(url => {
              photo_url = url
            })
            .catch(err => {
              console.error('Erro ao adicionar photo da criança. ', err.message)
              this.error = 'Erro ao adicionar photo da criança'
              this.loading = false
              return
            })
        }
        let document_url = null
        if(this.documentImage) {
          await uploadImageAsPromise(this.documentImage, 'children/documents')
            .then(url => {
              document_url = url
            })
            .catch(err => {
              console.error('Erro ao adicionar document da criança. ', err.message)
              this.error = 'Erro ao adicionar document da criança'
              this.loading = false
              return
            })
        }

        const child = {
          updated_at: new Date(),
          name: this.name,
          document: this.document,
          documentFormatted: this.document.replace(/[\W]/g,'').toUpperCase(),
          status: this.status,
          birthday: this.birthday,
          age: this.calculateAge(this.birthday),
          clothes: this.clothes,
          shoes: this.shoes,
          // documentUrl: url,
          type: 'child',
          gender: this.gender,
          yearParty: (new Date()).getFullYear(),
          year: this.year,
          mother_id: this.mother_id,
          id: this.id
        }

        if(!this.year.includes(this.defaultYearParty)) {
            this.year.push(this.defaultYearParty)
            child.status = 'cadastro'
            child.year = this.year
        }
        if(document_url) child.documentUrl = document_url
        if(photo_url) child.photoUrl = photo_url

        console.log("child", child)

        await this.updateChild(child)
          .then(async () => {
            this.showSnackbar('Cadastro atualizado com sucesso!', null)
            this.success_message = 'Cadastro realizado com sucesso!'
            this.loading = false
            this.children_dialog = false
          })
          .catch(err => {
            console.error(err)
            this.loading = false
            this.error = 'Erro ao realizar o cadastro'
            this.showSnackbar('Erro ao atualizar cadastro!', 'red')
          })
      },
      async acceptRules() {
        if (!this.$refs.form.validate()) {
          this.error = 'Informe os dados solicitados'
          this.dialog = false
          return
        }
        this.loading = true
        let duplicated = await isChildDuplicated(this.document)
        if(duplicated) {
          this.error = 'Mãe já está inscrita'
          this.dialog = false
          this.loading = false
          return
        }
        this.dialog = true
      },
      cancel() {
        this.$refs.form.reset()
        this.loading = false
        this.children_dialog = false
      },
      clearError() {
        this.error = ''
      },
      async findChildren(document) {
          if(!document) return
          this.loadingSearch = true
          let result = document.replace(/[\W]/g,'')
          let children = await this.$store.dispatch('children/getChildrenByDocument', result)
          console.log('children', children)
          if(children && children.id) {
              this.type = 'edit'
              this.new = false
              this.child = children
              this.document_old = children.document
              this.name = children.name
              this.birthday = children.birthday
              this.id = children.id
              this.age = this.calculateAge(this.birthday)
          }
          this.loadingSearch = false
      },
      validDate(date) {
        if(!date || date.length < 10) return false
        let dateParts = date.split("/");
        let current_year = (new Date()).getUTCFullYear()
        let year = dateParts[2]
        if(year > (current_year + 1)) return false
        if(!moment(date, 'DD/MM/YYYY',true).isValid()) return false
        return true
      },
      calculateAge(birthday) { // birthday is a date
        if(!birthday || birthday.length < 10) return false
        let dateParts = birthday.split("/");

        // month is 0-based, that's why we need dataParts[1] - 1
        let dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]); 
        // var ageDifMs = Date.now() - birthday.getTime();
        // var ageDifMs = new Date('2024-01-01').getTime() - dateObject.getTime();
        var ageDifMs = Date.now() - dateObject.getTime();
        var ageDate = new Date(ageDifMs); // miliseconds from epoch
        this.age = Math.abs(ageDate.getUTCFullYear() - 1970).toString()
        return Math.abs(ageDate.getUTCFullYear() - 1970);
      },
      calculaIdade(dataNasc) {
          var dataAtual = new Date();
          var anoAtual = dataAtual.getFullYear();
          var anoNascParts = dataNasc.split('/');
          var diaNasc =anoNascParts[0];
          var mesNasc =anoNascParts[1];
          var anoNasc =anoNascParts[2];
          var idade = anoAtual - anoNasc;
          var mesAtual = dataAtual.getMonth() + 1; 
          //Se mes atual for menor que o nascimento, nao fez aniversario ainda;  
          if(mesAtual < mesNasc){
              idade--; 
          } else {
          //Se estiver no mes do nascimento, verificar o dia
              if(mesAtual == mesNasc){ 
                  if(new Date().getDate() < diaNasc ){ 
                      //Se a data atual for menor que o dia de nascimento ele ainda nao fez aniversario
                      idade--; 
                  }
              }
          }
          return idade; 
      },
      showSnackbar(message, color) {
        this.snackbar_message = message
        if(color) this.snackbar_color = color
        this.snackbar = true
      },
      setWhatsAppMessage(child) {
            let msg = this.config.subscribe.whatsappMessageChild            
            msg = this.formatWhatAppMessage(msg, child)
            return msg
        },
        formatWhatAppMessage(message, child) {
            let variables = this.config.subscribe.variables || []
            // eslint-disable-next-line no-unused-vars
            let mother = child.mother
            const array = [];
            Object.keys(variables).forEach((key) => {
                array.push({
                    var: key,
                    ref: variables[key]
                })
            });
            array.find((v) => {
                // let vobj = v.ref.split('.')[0]
                // if(vobj === obj) {
                  message = message.replace('%7B%7B' + v.var + '%7D%7D', eval(v.ref))
                // }
            })
            
            return message ? message : ''
        }
    }
  }
</script>
