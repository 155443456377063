export const formatPhone = (phone, ddi) => {
    if(!phone) return ''
    if(!ddi) ddi = '55'
    let _phone = phone.replace(/\D/g, '')
    let initials = _phone.substring(0,2)
    if(initials === ddi) return _phone
    if(initials === "+" + ddi.substring(0,1)) return _phone
    if(_phone.length >= 10 || _phone.length >= 11) {
        _phone = ddi.toString() + _phone.toString()
    } else {
        console.error('Telefone sem DDD: ', _phone)
    }

    return _phone
}
export const reversePhone = (phone, ddi) => {
    if(!phone) return ''
    if(!ddi) ddi = '55'
    let _phone = phone.replace(/\D/g, '')
    let initials = _phone.substring(0,2)
    if(initials === ddi) return _phone.substring(2,_phone.length)
    return _phone
}