<template>
  <v-row justify="center">
    <v-navigation-drawer
      v-model="dialogUser"
      fixed
      right
      light
      width="350"
    >
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <v-card flat>
            <v-icon @click.stop="dialogUser = false">mdi-close</v-icon>
            <v-card-title>
                <span class="text-h5">{{type === 'new' ? 'Cadastrar' : 'Editar'}} Colaborador</span>
            </v-card-title>
            <v-card-text>
            <v-container>
                <v-row>
                    <v-text-field
                        v-model="name"
                        :rules="nameRules"
                        label="Nome"
                        required
                        outlined
                    />
                    <v-text-field
                        v-model.number="email"
                        :rules="emailRules"
                        label="E-mail"
                        required
                        outlined
                        :readonly="type !== 'new'"
                        :disabled="type !== 'new'"
                    />
                    <v-combobox
                        v-model="group"
                        :items="groups"
                        item-value="value"
                        item-text="label"
                        label="Grupo"
                        multiple
                        :return-object="false"
                        chips
                    ></v-combobox>
                    <v-text-field
                        v-model="password"
                        :rules="passwordRules"
                        label="Senha"
                        required
                        outlined
                        v-if="type === 'new'"
                    />
                    <v-text-field
                        v-model="repeatPassword"
                        :rules="repeatPasswordRules"
                        label="Repetir Senha"
                        required
                        outlined
                        v-if="type === 'new'"
                    />
                </v-row>
            </v-container>
            </v-card-text>
            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="indigo"
                @click="validate"
                dark
                block
            >
                Salvar
            </v-btn>
            </v-card-actions>
        </v-card>
      </v-form>
    </v-navigation-drawer>
    <v-snackbar
      v-model="snackbar"
      :multi-line="snackbarMultiLine"
      :color="snackbarColor"
      timeout=6000
      :top="true"
    >
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          <v-icon dark>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-row>
</template>
<script>
import { bus } from '../../main';
import { updateUser } from '../../services/users';
import { createUser } from '../../services/users'

export default {
    data () {
        return {
            type: 'new',
            dialogUser: false,
            valid: true,
            name: '',
            nameRules: [
                (v) => !!v || 'Ops! Informe seu nome completo.',
                (v) => (v && v.length >= 10) || 'Ops! Informe o nome completo.',
            ],
            group: [],
            groupRules: [
                (v) => (!!v || v.length <= 0) || 'Ops! Informe o grupo.',
            ],
            email: "",
            emailRules: [
              (v) => !!v || "Ops! Informe o e-mail.",
              (v) => /.+@.+\..+/.test(v) || "Ops! Informe um e-mail valido.",
            ],
            password: '',
            passwordRules: [
                (v) => !!v || 'Ops! Informe uma senha.',
                (v) => (v && v.length >= 8) || 'Ops! A senha deve ter pelo menos 8 digitos.',
            ],
            repeatPassword: '',
            repeatPasswordRules: [
                (v) => !!v || 'Ops! Informe uma senha igual a anterior.',
                (v) => (v && v.length >= 8) || 'Ops! A senha deve ter pelo menos 8 digitos.',
                (v) => (v && v.length >= 8 && v === this.password) || 'Ops! A senha deve ser igual a anterior.',
            ],
            error: null,
            loading: false,
            snackbar: false,
            snackbarColor: 'green',
            snackbarText: '',
            snackbarMultiLine: true,
            user: null,
            id: null,
            groups: [
                { value: 'admin', label: 'Organizador' }, 
                { value: 'responsible', label: 'Responsável Sacolinha' },
                { value: 'godfather', label: 'Padrinho' },
            ],
        }
    },
    created() {
        bus.$on('openDialogUser', (params) => {
            this.dialogUser = params.dialog;
            this.type = params.type
            if(params.user) {
                this.name = params.user.name
                this.email = params.user.email
                this.group = params.user.group
                this.user = params.user
                this.id = params.user.id
            }
        })
    },
    methods: {
        validate () {
            if (!this.$refs.form.validate()) {
                this.error = 'Informe os dados solicitados'
                this.dialog = false
                return
            }
            this.loading = true

            // Se for criação
            if(this.type === 'new') {
                createUser(this.email, this.name, this.password, this.group)
                    .then(() => {
                        this.showSnackBar('Cadastro realizado com sucesso. ', 'green')
                        this.dialog = false
                        this.loading = false
                    })
                    .catch(err => {
                        console.error(err)
                        this.error = 'Erro ao realizar o cadastro'
                        this.showSnackBar('Erro ao realizar o cadastro', 'red')
                        this.loading = false
                    })
            // Se for edição
            } else {
                let dataUser = {
                    name: this.name,
                    group: this.group
                }
                updateUser(this.id, dataUser)
                    .then(() => {
                        this.showSnackBar('Cadastro atualizado com sucesso. ', 'green')
                        this.dialog = false
                        this.loading = false
                    })
                    .catch(err => {
                        console.error(err)
                        this.error = 'Erro ao realizar o cadastro'
                        this.showSnackBar('Erro ao realizar o cadastro', 'red')
                        this.loading = false
                    })
            }
        },
        showSnackBar(text, color) {
            this.snackbarText = text,
            this.snackbarColor = color
            this.snackbar = true
        },
        clear() {
            this.type = 'new'
            this.name = ''
            this.phone = ''
            this.qtdeFilhos = ''
            this.isWhatsApp = true
            this.error = null
            this.user = null
            this.id = null
            this.valid = true
        }
    }
}
</script>