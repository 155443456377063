<template>
    <v-container fluid>
        <v-row class="pb-4">
            <v-col cols="12" sm="3" lg="3">
                <v-card
                    class="mx-auto pb-4"
                    color="red" 
                    dark
                >
                    <v-row>
                        <v-col
                            class="text-h2"
                            cols="8"
                        >
                            <v-list-item two-line>
                                <v-list-item-icon>
                                    <v-icon>basket-outline</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title class="text-h5">
                                        Cestas Básicas
                                    </v-list-item-title>
                                    <v-list-item-subtitle>Cadastradas</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-col>
                        <v-col
                            class="text-h4 mt-4"
                            cols="4"
                        >
                            {{ totalBeneficiados }}
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
        <v-card>
            <v-card-title>
                <v-row class="pa-2" align="center">
                    Beneficiados
                    <v-btn
                        class="mx-2"
                        fab
                        small
                        dark
                        color="indigo"
                        @click="insertBeneficiado"
                        >
                        <v-icon dark small>
                            mdi-plus
                        </v-icon>
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Buscar"
                        single-line
                        hide-details
                        clearable
                    ></v-text-field>
                    <v-menu
                        open-on-hover
                        top
                        offset-y
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                            color="indigo"
                            dark
                            v-bind="attrs"
                            v-on="on"
                            icon
                            >
                            <v-icon large>mdi-microsoft-excel</v-icon>
                            </v-btn>
                        </template>

                        <v-list>
                            <v-list-item link>
                                <download-excel
                                    class="btn btn-default"
                                    :data="beneficiados"
                                    :fields="json_fields"
                                    worksheet="beneficiados"
                                    :name="'cestabasica_geral_' + new Date().getTime() + '.xls'"
                                >
                                    <v-list-item-title link>Todas</v-list-item-title>
                                </download-excel>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </v-row>
            </v-card-title>
            <v-data-table
                dense
                v-if="beneficiados && beneficiados.length > 0"
                :headers="headers"
                :items="beneficiadosList"
                :search="search"
                :loading="loading"
                loading-text="Buscando mãe... Por favor, aguarde!"
            >
                <template v-slot:item.name="{ item }">
                    <div :class="item.status === 'desativado' ? 'red--text' : ''">
                        {{ item.name }}
                    </div>
                </template>
                
                <template v-slot:item.phone="{ item }">
                    {{ item.phone }}
                    <v-btn v-if="item.isWhatsApp" icon color="green" :href="'https://api.whatsapp.com/send?phone=' + item.phone + '&text=' + setWhatsAppMessage(item)" target="_blank"><v-icon right color="green">mdi-whatsapp</v-icon></v-btn>
                </template>
                <template v-slot:item.situacao="{ item }">
                    <v-chip small class="mt-1">{{ item.situacao }}</v-chip>
                </template>
                <template v-slot:item.aluguel="{ item }">
                    {{ item.aluguel ? "Sim" : "Não" }}
                </template>
                
                <template v-slot:item.actions="{ item }">
                    <v-icon
                        small
                        class="mr-2"
                        @click="editItem(item)"
                    >
                        mdi-pencil
                    </v-icon>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                small
                                @click="deleteItem(item, item.status === 'desativado' ? item.status_old : 'desativado')"
                                :color="item.status === 'desativado' ? 'red' : 'green'"
                                v-bind="attrs"
                                v-on="on"
                            >
                                mdi-delete
                            </v-icon>
                        </template>
                        <span>{{item.status === 'desativado' ? 'Ativar' : 'Desativar'}}</span>
                    </v-tooltip>
                </template>
                <template v-slot:no-data>
                </template>
            </v-data-table>
        </v-card>
        <beneficiado-form/>
        <v-row justify="center">
            <v-dialog
                v-model="dialog_drawn"
                persistent
                max-width="290"
            >
            <v-card>
                <v-card-title class="text-h5">
                Sorteio
                </v-card-title>
                <v-card-text>Mar.</v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="green darken-1"
                    text
                    @click="dialog_drawn = false"
                >
                    Disagree
                </v-btn>
                <v-btn
                    color="green darken-1"
                    text
                    @click="dialog_drawn = false"
                >
                    Agree
                </v-btn>
                </v-card-actions>
            </v-card>
            </v-dialog>
        </v-row>
        <v-snackbar
            v-model="snackbar"
            :multi-line="snackbarMultiLine"
            :color="snackbarColor"
            timeout=6000
            :top="true"
            >
            {{ snackbarText }}

            <template v-slot:action="{ attrs }">
                <v-btn
                text
                v-bind="attrs"
                @click="snackbar = false"
                >
                <v-icon dark>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>
    </v-container>
</template>
<script>
  import { updateBeneficiado } from '../../services/beneficiados'
  import { mapActions, mapGetters, mapState } from 'vuex'
  import BeneficiadoForm from '../../components/admin/BeneficiadoForm.vue'
  import { bus } from '../../main';
  export default {
    data () {
      return {
        search: '',
        headers: [
          {
            text: 'Nome da Mãe',
            align: 'start',
            sortable: true,
            value: 'name',
          },
          { text: 'Documento', value: 'document' },
          { text: 'Situação', value: 'situacao' },
          { text: 'Filhos até 10 anos', value: 'filhosAte10' },
          { text: 'Filhos > 10 anos', value: 'filhosMaior10' },
          { text: 'Telefone', value: 'phone' },
          { text: 'Local', value: 'localidade' },
          { text: 'Aluguel', value: 'aluguel' },
        //   { text: 'Sorteado', value: 'prize'},
          { text: 'Ações', value: 'actions' },
        ],
        beneficiadosList: [],
        loading: false,
        dialog: false,
        dialog_drawn: false,
        snackbar: false,
        snackbarColor: 'green',
        snackbarText: '',
        snackbarMultiLine: true,
        loading_drawn: false,
        json_fields: {
            "Nome": "name",
            "Documento": "document",
            "Telefone": "phone",
            "Senha": "token",
            "Status": "status",
            "WhatsApp?": "isWhatsApp",
            "ID": "id"
        },
        drawned: false,
        drawnedbag: false,
      }
    },
    components: {
        BeneficiadoForm,
    },
    computed: {
        ...mapState('cestabasica', ['beneficiados']),
        ...mapState(['config']),
        ...mapGetters('cestabasica', ['totalBeneficiados'])
    },
    async created() {
        if(!this.beneficiados || this.beneficiados?.length <= 0) {
            this.loading = true
            await this.getBeneficiadosByParam(null)
                .then(() => {
                    this.loading = false
                })
                .catch((error => {
                    console.error(error)
                    this.loading = false
                }))
        }
        if(this.beneficiados) this.beneficiadosList = this.beneficiados
    },
    watch: {
        beneficiados() {
            this.beneficiadosList = this.mothers
        }
    },
    methods: {
        ...mapActions('cestabasica', ['getBeneficiadosByParam']),
        
        editItem(item) {
            bus.$emit('openDialogBeneficiado', {dialogBeneficiadoForm: true, beneficiado: item})
        },
        drawnFilter(drawned) {
            if(drawned) {
                this.beneficiadosList = this.getDrawnMothers
            }
            else this.beneficiadosList = this.mothers
        },
        bagDrawnFilter(drawned) {
            if(drawned) {
                this.beneficiadosList = this.getBagDrawnMothers
            }
            else this.beneficiadosList = this.mothers
        },
        basketDrawnFilter(drawned) {
            if(drawned) {
                this.beneficiadosList = this.getBasketDrawnMothers
            }
            else this.beneficiadosList = this.mothers
        },
        prayDrawnFilter(drawned) {
            if(drawned) {
                this.beneficiadosList = this.getPrayDrawnMothers
            }
            else this.beneficiadosList = this.mothers
        },
        insertBeneficiado() {
            let beneficio = {
                type: 'new',
                name: '',
                phone: '',
                qtdeFilhos: '',
                isWhatsApp: true,
                error: null,
                // token: customAlphabet('1234567890', 4)(),
                mother: null,
                id: null,
                valid: true,
            }
            bus.$emit('openDialogBeneficiado', {dialogBeneficiadoForm: true, beneficio: beneficio})
        },
        setSortable(id, drawn) {
            this.loading_drawn = id
            let data = {
                drawn: !drawn,
                status: (!drawn || drawn === false) ? 'sorteada sitio': 'cadastro'
            }
            updateBeneficiado(id, data)
                .then(() => {
                    this.showSnackBar('Mãe marcada como sorteada para o sitio', 'green')
                    this.loading_drawn = false
                })
                .catch((err) => {
                    console.error(err)
                    this.showSnackBar('Ops! Erro ao marcar mãe como sorteada para o sitio', 'red')
                    this.loading_drawn = false
                })
        },
        deleteItem(item, status) {
            this.loading_delete = item.id
            let data = {
                status: status,
                status_old: item.status
            }
            updateBeneficiado(item.id, data)
                .then(() => {
                    this.showSnackBar('Cadastro desabilitado com sucesso', 'green')
                    this.loading_delete = false
                })
                .catch((err) => {
                    console.error(err)
                    this.showSnackBar('Ops! Erro ao desabilitar o cadastro', 'red')
                    this.loading_delete = false
                })
        },
        showSnackBar(text, color) {
            this.snackbarText = text,
            this.snackbarColor = color
            this.snackbar = true
        },
        setWhatsAppMessage(mother) {
            // let firstname = mother.name.split(' ')[0]
            // firstname = firstname.charAt(0).toUpperCase() + firstname.slice(1);
            let firstname = mother.name
            // let msg = 'Ol%C3%A1%20'+firstname+'%2C%20sou%20do%20Lar%20Ismael%2C%20suas%20crian%C3%A7as%20foram%20sorteadas%20para%20participar%20da%2025%C2%AA%20Festa%20de%20Natal%20do%20Lar%20Ismael%20'+(mother.drawnBag ? '*para%20receber%20a%20sacola%20de%20Natal%20dia%2017%20de%20dezembro*.' : 'no%20*s%C3%ADtio*.')+'%0A%0AVc%20vai%20precisar%20entrar%20no%20link%20abaixo%20para%20cadastrar%20o(s)%20dado(s)%20da(s)%20sua(s)%20crian%C3%A7as(s)%2C%20at%C3%A9%20dia%2024%20de%20agosto.%0Ahttps%3A%2F%2Flarismael.org%2Fcadastro%2Ffilhos%2F'+mother.id+'%0A%0A*No%20v%C3%ADdeo%20tem%20tudo%20explicadinho%20como%20voc%C3%AA%20tem%20que%20fazer%2C%20qualquer%20d%C3%BAvida%20fale%20com%20a%20gente%20*respondendo%20a%20essa%20mensagem*.%0A%0AN%C3%B3s%20do%20Lar%20Ismael%2C%20vamos%20conferir%20os%20documentos%20das%20crian%C3%A7as%2C%20%C3%A9%20muito%20importante%20que%20a%20foto%20esteja%20leg%C3%ADvel.%0A%0AQuando%20terminarmos%20de%20conferir%20os%20dados%20da%20sua%20fam%C3%ADlia%2C%20voc%C3%AA%20vai%20receber%20mais%20uma%20mensagem%20dizendo%20que%20seu%20cadastro%20est%C3%A1%20ok.%20%0A%0A*Estamos%20muito%20felizes%20de%20ter%20voc%C3%AAs%20com%20a%20gente%20neste%20ano!!!%20Que%20Deus%20aben%C3%A7oe%20a%20todos!*'
            let msg = this.config.subscribe.whatsappMessageCestaBasica || 'Ol%C3%A1%20'+firstname+'%2C%20sou%20do%20Lar%20Ismael%2C%20suas%20crian%C3%A7as%20foram%20sorteadas%20para%20participar%20da%2025%C2%AA%20Festa%20de%20Natal%20do%20Lar%20Ismael%20'+(mother.drawnBag ? '*para%20receber%20a%20sacola%20de%20Natal%20dia%2017%20de%20dezembro*.' : 'no%20*s%C3%ADtio*.')+'%0A%0AVc%20vai%20precisar%20entrar%20no%20link%20abaixo%20para%20cadastrar%20o(s)%20dado(s)%20da(s)%20sua(s)%20crian%C3%A7as(s)%2C%20at%C3%A9%20dia%2024%20de%20agosto.%0Ahttps%3A%2F%2Flarismael.org%2Fcadastro%2Ffilhos%2F'+mother.id+'%0A%0A*No%20v%C3%ADdeo%20tem%20tudo%20explicadinho%20como%20voc%C3%AA%20tem%20que%20fazer%2C%20qualquer%20d%C3%BAvida%20fale%20com%20a%20gente%20*respondendo%20a%20essa%20mensagem*.%0A%0AN%C3%B3s%20do%20Lar%20Ismael%2C%20vamos%20conferir%20os%20documentos%20das%20crian%C3%A7as%2C%20%C3%A9%20muito%20importante%20que%20a%20foto%20esteja%20leg%C3%ADvel.%0A%0AQuando%20terminarmos%20de%20conferir%20os%20dados%20da%20sua%20fam%C3%ADlia%2C%20voc%C3%AA%20vai%20receber%20mais%20uma%20mensagem%20dizendo%20que%20seu%20cadastro%20est%C3%A1%20ok.%20%0A%0A*Estamos%20muito%20felizes%20de%20ter%20voc%C3%AAs%20com%20a%20gente%20neste%20ano!!!%20Que%20Deus%20aben%C3%A7oe%20a%20todos!*'
            
            msg = this.formatWhatAppMessage(msg, mother, 'mother')
            return msg
        },
        formatWhatAppMessage(message, mother, obj) {
            let variables = this.config.subscribe.variables || []
            const array = [];
            Object.keys(variables).forEach((key) => {
                array.push({
                    var: key,
                    ref: variables[key]
                })
            });
            array.find((v) => {
                let vobj = v.ref.split('.')[0]
                if(vobj === obj) {
                    message = message.replace('%7B%7B' + v.var + '%7D%7D', eval(v.ref))
                }
            })
            
            return message
        }

    },
  }
</script>