<template>
  <v-row justify="center">
    <!-- <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
    > -->
    <v-navigation-drawer
      v-model="dialog"
      fixed
      right
      light
      width="350"
    >
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <v-card flat>
            <v-icon @click.stop="close">mdi-close</v-icon>
            <v-card-title>
                <span class="text-h5">{{type === 'new' ? 'Cadastrar' : 'Editar'}} Mãe</span>
            </v-card-title>
            <v-card-text>
            <v-container>
                <v-row v-if="type === 'edit'">
                    ID: {{mother.id}}
                </v-row>
                <v-row>
                <!-- <v-col
                    cols="12"
                    sm="8"
                    md="8"
                > -->
                    <v-text-field
                        v-model="document"
                        :rules="documentRules"
                        label="Nº do RG da Mãe"
                        placeholder="Sem traços e sem pontos"
                        required
                        @change="findMother(document)"
                        :disabled="type === 'edit'"
                        append-icon="mdi-magnify"
                        :loading="loadingSearch"
                        @click:append="findMother(document)"
                    >
                    </v-text-field>
                    <v-checkbox
                        v-model="onlyBasket"
                        label="Somente Cesta Básica"
                    ></v-checkbox>
                    <v-text-field
                        v-model="name"
                        :rules="nameRules"
                        :label="onlyBasket ? 'Nome' : 'Nome da Mãe'"
                        required
                        :disabled="loadingSearch"
                    />
                <!-- </v-col>
                <v-col
                    cols="12"
                    sm="4"
                    md="4"
                > -->
                    <v-text-field
                        v-model.number="qtdeFilhos"
                        v-mask="'##'"
                        :rules="qtdeFilhosRules"
                        label="Quantidade de Filhos"
                        type="number"
                        required
                        :disabled="loadingSearch"
                    />
                <!-- </v-col>
                <v-col
                    cols="12"
                    sm="4"
                    md="4"
                > -->
                    <v-text-field
                        v-model="phone"
                        v-mask="'(##)#####-####'"
                        :rules="phoneRules"
                        placeholder="(11)99999-9999"
                        label="Telefone"
                        required
                        type="tel"
                        :disabled="loadingSearch"
                    />
                    
                <!-- </v-col>
                <v-col cols="12" sm="4" md="4"> -->
                    <v-radio-group
                        v-model="isWhatsApp"
                        :rules="[(v) => v.length > 0 || 'Ops! Informe se o telefone é WhatsApp.']"
                        :disabled="loadingSearch"
                        >
                        <template v-slot:label>
                            <div>Esse número de telefone possui <strong>Whatsapp?</strong></div>
                        </template>
                        <v-radio
                            label="Não"
                            value=false
                        ></v-radio>
                        <v-radio
                            label="Sim"
                            value=true
                        ></v-radio>
                    </v-radio-group>
                    <v-autocomplete
                        :items="places"
                        v-model="pickUpPlace"
                        color="white"
                        label="Localidade"
                        :disabled="loadingSearch || (!(Array.isArray(drawnBag) && drawnBag?.length > 0) && !(Array.isArray(drawn) && drawn?.length > 0))"
                    ></v-autocomplete>
                    <br>
                <!-- </v-col>
                <v-col
                    cols="12"
                    sm="4"
                    md="4"
                > -->
                    <v-text-field
                        v-model="token"
                        v-mask="'####'"
                        label="Senha"
                        required
                        type="tel"
                        readonly
                        disabled
                    />
                <!-- </v-col> -->
                </v-row>
                <v-row v-if="id">
                    Inscrições: {{ this.year && this.year.length > 0 ? this.year.join(', ') : 'Nunca participou.' }}
                </v-row>
                <v-row v-if="id">
                    Festas: {{ this.party && this.party.length > 0 ? this.party.join(', ') : 'Nunca participou.' }}
                </v-row>
            </v-container>
            <!-- <small>*indicates required field</small> -->
            </v-card-text>
            <v-card-actions>
            <v-spacer></v-spacer>
            <!-- <v-btn
                color="blue darken-1"
                text
                @click="clear"
            >
                Limpar
            </v-btn> -->
            <v-btn
                color="indigo"
                @click="validate"
                dark
                block
                :disabled="loadingSearch"
            >
                Salvar
            </v-btn>
            </v-card-actions>
        </v-card>
      </v-form>
    </v-navigation-drawer>
    <!-- </v-dialog> -->
    <v-snackbar
      v-model="snackbar"
      :multi-line="snackbarMultiLine"
      :color="snackbarColor"
      timeout=6000
      :top="true"
    >
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          <v-icon dark>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-row>
</template>
<script>
import { bus } from '../../main';
import { setMother, updateMother, isMotherDuplicated, validaTokenDup } from '../../services/mothers'
import { customAlphabet } from 'nanoid/non-secure'
import { formatPhone, reversePhone } from '../../utils/phone'
import { mapState } from 'vuex';

export default {
    data () {
        return {
            type: 'new',
            dialog: false,
            valid: true,
            name: '',
            onlyBasket: false,
            nameRules: [
                (v) => !!v || 'Ops! Informe seu nome completo.',
                (v) => (v && v.length >= 10) || 'Ops! Informe seu nome completo.',
            ],
            phone: '',
            phoneRules: [
                (v) => !!v || 'Ops! Informe seu WhatsApp ou telefone.',
                (v) => (v && v.length >= 10) || 'Ops! Informe seu telefone.',
            ],
            qtdeFilhos: '',
            qtdeFilhosRules: [
                (v) => !!v || 'Ops! Informe a quantidade de filhos.',
                (v) => (v && v >= 0) || 'Ops! Informe a quantidade de filhos.',
            ],
            // email: "",
            // emailRules: [
            //   (v) => !!v || "E-mail is required",
            //   (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
            // ],
            isWhatsApp: true,
            subscriptionRules: true,
            error: null,
            loading: false,
            token: customAlphabet('1234567890', 4)(),
            snackbar: false,
            snackbarColor: 'green',
            snackbarText: '',
            snackbarMultiLine: true,
            mother: null,
            id: null,
            status: null,
            document: '',
            documentRules: [
                (v) => !!v || 'Ops! Informe seu RG.',
                (v) => (v && v.length >= 6) || 'Ops! Informe seu RG.',
            ],
            places: [
                'Aruja Sacola',
                'Evangelização Sítio',
                'Joana Darc Sítio',
                'Ibirapuera Sítio',
                'Penha Sítio',
                'Evangelização Sacola',
                'Joana Darc Sacola',
                'Ibirapuera Sacola',
                'Penha Sacola'
            ],
            pickUpPlace: '',
            party: [],
            year: [],
            drawnBasket: false,
            drawnPray: false,
            loadingSearch: false,
            drawnBag: [],
            drawn: [],
        }
    },
    computed: {
        ...mapState(['defaultYearParty', 'selectedYear'])
    },
    created() {
        bus.$on('openDialog', async (params) => {
            this.dialog = params.dialog;
            if(params.mother) {
                this.type = params.mother.type
                
                // let dup = false
                // let count = 0
                if(params.mother.type === 'new') {
                    this.mother = 'new'
                    this.token = customAlphabet('1234567890ABCDEFGHIJKLMN', 4)()
                } else {
                    this.type = 'edit'
                    this.token = params.mother.token
                }
                this.onlyBasket = params.mother.onlyBasket
                this.name = params.mother.name
                this.phone = reversePhone(params.mother.phone)
                this.isWhatsApp = params.mother.isWhatsApp.toString()
                this.qtdeFilhos = params.mother.sons
                this.mother = params.mother
                this.id = params.id
                this.status = params.mother.status
                this.document = params.mother.document
                this.party = params.mother.party
                this.year = params.mother.year
                this.drawnBasket = params.mother.drawnBasket
                this.drawnPray = params.mother.drawnPray
                this.drawnBag = params.mother.drawnBag
                this.drawn = params.mother.drawn
                if(((Array.isArray(this.drawnBag) && this.drawnBag?.length > 0 && this.drawnBag.includes(this.defaultYearParty)) || (Array.isArray(this.drawn) && this.drawn?.length > 0 && this.drawn.includes(this.defaultYearParty))))
                    this.pickUpPlace = params.mother.pickUpPlace
            }
        })
        
    },
    methods: {
        async validate () {
            if (!this.$refs.form.validate()) {
                this.error = 'Informe os dados solicitados'
                this.dialog = false
                return
            }
            this.loading = true

            // Se for edição
            if(this.mother && this.mother.id) {
                // Caso contrario
                const update_mother = {
                    updated_at: new Date(),
                    status: this.status || 'cadastro',
                    name: this.name,
                    phone: formatPhone(this.phone),
                    sons: this.qtdeFilhos,
                    isWhatsApp: this.isWhatsApp === "true",
                    type: 'mother',
                    token: this.token,
                    yearParty: (new Date()).getFullYear(),
                    pickUpPlace: this.pickUpPlace ?? '',
                    documentFormatted: this.document.replace(/[\W]/g,'').toUpperCase()

                }
                if(!this.onlyBasket) {
                    if(!this.year.includes(this.defaultYearParty)) {
                        this.year.push(this.defaultYearParty)
                        update_mother.year = this.year
                    }
                }
                updateMother(this.mother.id, update_mother)
                    .then(() => {
                        this.$store.commit('setMother', { ...this.mother, ...update_mother})
                        this.showSnackBar('Cadastro atualizado com sucesso. ', 'green')
                        this.dialog = false
                        this.loading = false
                    })
                    .catch(err => {
                        console.error(err)
                        this.error = 'Erro ao realizar o cadastro'
                        this.showSnackBar('Erro ao realizar o cadastro', 'red')
                        this.loading = false
                    })
            } else {
                let dup = await validaTokenDup(this.token, (new Date()).getFullYear())
                if(dup) this.token = customAlphabet('1234567890ABC', 4)()

                // Caso contrario
                const new_mother = {
                    created_at: new Date(),
                    updated_at: new Date(),
                    status: 'cadastro',
                    name: this.name,
                    phone: formatPhone(this.phone),
                    sons: this.qtdeFilhos,
                    isWhatsApp: this.isWhatsApp === "true",
                    type: 'mother',
                    token: this.token,
                    yearParty: (new Date()).getFullYear(),
                    document: this.document,
                    pickUpPlace: this.pickUpPlace ?? '',
                    documentFormatted: this.document.replace(/[\W]/g,'').toUpperCase()
                }
                if(!this.onlyBasket) new_mother.year = [this.defaultYearParty]
                let duplicated = await isMotherDuplicated(this.document, this.selectedYear)
                if(duplicated) {
                    this.showSnackBar('Já existe uma mãe escrita com esse documento', 'red')
                    this.error = 'Mãe já está inscrita'
                    //  this.dialog = false
                    this.loading = false
                    return
                }
                setMother(new_mother)
                .then(() => {
                    this.$store.commit('setMother', new_mother)
                    this.showSnackBar('Cadastro realizado com sucesso. ', 'green')
                    this.dialog = false
                    this.loading = false
                })
                .catch(err => {
                    console.error(err)
                    this.error = 'Erro ao realizar o cadastro'
                    this.showSnackBar('Erro ao realizar o cadastro', 'red')
                    this.loading = false
                })
            }
        },
        showSnackBar(text, color) {
            this.snackbarText = text,
            this.snackbarColor = color
            this.snackbar = true
        },
        close() {
            this.clear()
            this.dialog = false
        },
        clear() {
            this.type = 'new'
            this.name = ''
            this.phone = ''
            this.qtdeFilhos = ''
            this.isWhatsApp = true
            this.error = null
            this.token = customAlphabet('1234567890', 4)(),
            this.mother = null
            this.id = null
            this.valid = true
            this.pickUpPlace = ''
            this.drawnBasket = false
            this.drawnPray = false
            this.drawnBag = []
            this.drawn = []
            this.year = []
            this.party = []
        },
        clearError() {
            this.error = ''
        },
        async findMother(document) {
            if(!document) return
            this.loadingSearch = true
            let result = document.replace(/[\W]/g,'')
            let mother = await this.$store.dispatch('getMothersByDocument', result)
            if(mother && mother.id) {
                this.type = 'edit'
                this.onlyBasket = mother.onlyBasket
                this.name = mother.name
                this.phone = reversePhone(mother.phone)
                this.qtdeFilhos = mother.sons
                this.isWhatsApp = mother.isWhatsApp.toString()
                this.error = null
                this.token = mother.token
                this.mother = mother
                this.id = mother.id
                this.status = mother.status
                this.party = mother.party
                this.year = mother.year
                this.drawnBasket = mother.drawnBasket
                this.drawnPray = mother.drawnPray
                this.drawnBag = mother.drawnBag
                this.drawn = mother.drawn     
                if(((Array.isArray(this.drawnBag) && this.drawnBag?.length > 0 && this.drawnBag.includes(this.defaultYearParty)) || (Array.isArray(this.drawn) && this.drawn?.length > 0 && this.drawn.includes(this.defaultYearParty))))
                    this.pickUpPlace = mother.pickUpPlace
            }
            this.loadingSearch = false
        }
    }
}
</script>