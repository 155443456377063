<template>
  <div class="vuepdf">
    <h1>{{ msg }}</h1>
    <!-- <div id="pdf">
      <button v-on:click="pdfgen">Generate PDF</button>
    </div> -->
    <iframe
            :src="dataUrl"
            width="100%"
            height="1000"
            frameborder="0" >
    </iframe>
  </div>
</template>

<script>
import hangtag from '../../pdf/hangTags'
import { getMothersAndChildrenTag } from '../../services/mothers'
export default {
  name: 'Pdfgen',
  props: {
    msg: String
  },
  data () {
      return {
          dataUrl: null
      }
  },
  async mounted() {
        var pdfMake = require('pdfmake/build/pdfmake.js')
        if (pdfMake.vfs == undefined){
            var pdfFonts = require('pdfmake/build/vfs_fonts.js')
            pdfMake.vfs = pdfFonts.pdfMake.vfs;
        }
        let childrenList = await getMothersAndChildrenTag()
        var docDefinition = hangtag.template(childrenList)
        const pdfDocGenerator = pdfMake.createPdf(await docDefinition)
        pdfDocGenerator.getDataUrl((dataUrl) => {
            this.dataUrl = dataUrl
        });
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>