<template>
  <v-row justify="center">
    <!-- <v-dialogBeneficiadoForm
      v-model="dialog"
      persistent
      max-width="600px"
    > -->
    <v-navigation-drawer
      v-model="dialogBeneficiadoForm"
      fixed
      right
      light
      width="350"
    >
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <v-card flat>
            <v-icon @click.stop="dialogBeneficiadoForm = !dialogBeneficiadoForm">mdi-close</v-icon>
            <v-card-title>
                <span class="text-h5">{{type === 'new' ? 'Cadastrar' : 'Editar'}} Beneficiado</span>
            </v-card-title>
            <v-card-text>
            <v-container>
                <v-row v-if="type === 'edit'">
                    ID: {{beneficiado.id}}
                </v-row>
                <v-row>
                    <v-text-field
                        v-model="name"
                        :rules="nameRules"
                        label="Nome"
                        required
                    />
                    <v-autocomplete
                        v-model="situacao"
                        :items="situacao_items"
                        outlined
                        placeholder="Digite ou escolha na lista"
                        required
                        color="blue-grey lighten-2"
                        label="Situação"
                        :rules="situacaoRules"
                    />
                    <v-text-field
                        v-model="indicadoPor"
                        :rules="nameRules"
                        label="Indicado Por"
                        required
                        v-if="situacao === 'Indicação'"
                    />
                    <v-text-field
                        v-model="phone"
                        v-mask="'(##)#####-####'"
                        :rules="phoneRules"
                        placeholder="(11)99999-9999"
                        label="Telefone"
                        required
                        type="tel"
                    />
                    <v-radio-group
                        v-model="isWhatsApp"
                        :rules="[ (v) => !!v || 'Ops! Informe se o telefone é WhatsApp.']"
                        >
                        <template v-slot:label>
                            <div>Esse número de telefone possui <strong>Whatsapp?</strong></div>
                        </template>
                        <v-radio
                            label="Não"
                            :value=false
                        ></v-radio>
                        <v-radio
                            label="Sim"
                            :value=true
                        ></v-radio>
                    </v-radio-group>
                    
                    
                    <v-text-field
                        v-model="document"
                        :rules="documentRules"
                        label="Nº do RG"
                        placeholder="Sem traços e sem pontos"
                        @change="clearError"
                        :disabled="type === 'edit'"
                    />
                    <v-text-field
                        v-model="bairro"
                        label="Bairro"
                    />
                    <v-text-field
                        v-model="localidade"
                        label="Localidade"
                        disabled=""
                    />
                    <v-text-field
                        v-model.number="filhosAte10"
                        v-mask="'##'"
                        label="Quantidade de Filhos até 10 anos"
                        type="number"
                    />
                    <v-text-field
                        v-model.number="filhosMaior10"
                        v-mask="'##'"
                        label="Quantidade de Filhos maior que 10 anos"
                        type="number"
                    />
                    <v-text-field
                        v-model.number="adultos"
                        v-mask="'##'"
                        label="Quantidade de Adultos"
                        type="number"
                    />
                    <v-col cols="12">
                        <v-radio-group
                            v-model="aluguel"
                        >
                            <template v-slot:label>
                                <div>Mora de Aluguel?</div>
                            </template>
                            <v-radio
                                label="Não"
                                :value=false
                            ></v-radio>
                            <v-radio
                                label="Sim"
                                :value=true
                            ></v-radio>
                        </v-radio-group>
                    </v-col>
                    <v-text-field
                        v-model.number="valorAluguel"
                        v-mask="'##'"
                        label="Valor de Aluguel"
                        type="number"
                        v-if="aluguel"
                    />
                    <v-text-field
                        v-model.number="comodos"
                        v-mask="'##'"
                        label="Quantidade de Comodos"
                        type="number"
                    />
                    <v-text-field
                        v-model.number="conjuge"
                        label="Conjuge"
                    />
                    <v-textarea
                        v-model="observacao"
                        label="Observação"
                    >

                    </v-textarea>
                <!-- </v-col>
                <v-col cols="12" sm="4" md="4"> -->
                    
                <!-- </v-col>
                <v-col
                    cols="12"
                    sm="4"
                    md="4"
                > -->
                
                    <v-text-field
                        v-model="token"
                        v-mask="'####'"
                        label="Senha"
                        required
                        type="tel"
                        readonly
                        disabled
                    />
                <!-- </v-col> -->
                </v-row>
            </v-container>
            <!-- <small>*indicates required field</small> -->
            </v-card-text>
            <v-card-actions>
            <v-spacer></v-spacer>
            <!-- <v-btn
                color="blue darken-1"
                text
                @click="clear"
            >
                Limpar
            </v-btn> -->
            <v-btn
                color="indigo"
                @click="validate"
                dark
                block
            >
                Salvar
            </v-btn>
            </v-card-actions>
        </v-card>
      </v-form>
    </v-navigation-drawer>
    <!-- </v-dialogBeneficiadoForm> -->
    <v-snackbar
      v-model="snackbar"
      :multi-line="snackbarMultiLine"
      :color="snackbarColor"
      timeout=6000
      :top="true"
    >
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          <v-icon dark>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-row>
</template>
<script>
import { bus } from '../../main';
import { setBeneficiado, updateBeneficiado } from '../../services/beneficiados'
import { customAlphabet } from 'nanoid/non-secure'
import { formatPhone, reversePhone } from '../../utils/phone'
import { mapState } from 'vuex';

export default {
    data () {
        return {
            type: 'new',
            dialogBeneficiadoForm: false,
            valid: true,
            name: '',
            nameRules: [
                (v) => !!v || 'Ops! Informe seu nome completo.',
                (v) => (v && v.length >= 10) || 'Ops! Informe seu nome completo.',
            ],
            phone: '',
            phoneRules: [
                (v) => !!v || 'Ops! Informe seu WhatsApp ou telefone.',
                (v) => (v && v.length >= 10) || 'Ops! Informe seu telefone.',
            ],
            situacao: '',
            situacaoRules: [
                (v) => !!v || 'Ops! Informe a situação do beneficiado.',
            ],
            situacao_items: ['Assistido', 'Evangelização online', 'Evangelização presencial', 'Sirineu', 'Indicação'],
            indicadoPor: '',
            qtdeFilhos: '',
            qtdeFilhosRules: [
                (v) => !!v || 'Ops! Informe a quantidade de filhos.',
                (v) => (v && v >= 0) || 'Ops! Informe a quantidade de filhos.',
            ],
            bairro: '',
            localidade: '',
            filhosAte10: 0,
            filhosMaior10: 0,
            tipoFilhosAte10: '',
            adultos: 0,
            aluguel: '',
            valorAluguel: 0,
            comodos: 0,
            conjuge: '',
            observacao: '',
            // email: "",
            // emailRules: [
            //   (v) => !!v || "E-mail is required",
            //   (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
            // ],
            isWhatsApp: true,
            subscriptionRules: true,
            error: null,
            loading: false,
            token: customAlphabet('1234567890', 4)(),
            snackbar: false,
            snackbarColor: 'green',
            snackbarText: '',
            snackbarMultiLine: true,
            beneficiado: null,
            id: null,
            status: null,
            document: '',
            documentRules: [
                (v) => !!v || 'Ops! Informe seu RG.',
                (v) => (v && v.length >= 6) || 'Ops! Informe seu RG.',
            ],
            statusList: ['ativo', 'cancelado']
        }
    },
    computed: {
        ...mapState(['defaultYearParty', 'selectedYear'])
    },
    created() {
        bus.$on('openDialogBeneficiado', async (params) => {
            this.dialogBeneficiadoForm = params.dialogBeneficiadoForm;
            if(params.beneficiado) {
                this.type = params.beneficiado.type
                
                // let dup = false
                // let count = 0
                if(params.beneficiado.type === 'new') {
                    this.beneficiado = 'new'
                    this.token = customAlphabet('1234567890ABCDEFGHIJKLMN', 4)()
                } else {
                    this.type = 'edit'
                    this.token = params.beneficiado.token
                }
                this.name = params.beneficiado.name
                this.situacao = params.beneficiado.situacao
                this.bairro = params.beneficiado.bairro
                this.localidade = params.beneficiado.localidade
                this.filhosAte10 = params.beneficiado.filhosAte10
                this.filhosMaior10 = params.beneficiado.filhosMaior10
                this.adultos = params.beneficiado.adultos
                this.aluguel = params.beneficiado.aluguel
                this.valorAluguel = params.beneficiado.valorAluguel
                this.phone = reversePhone(params.beneficiado.phone)
                this.isWhatsApp = params.beneficiado.isWhatsApp
                this.beneficiado = params.beneficiado
                this.id = params.id
                this.status = params.beneficiado.status
                this.document = params.beneficiado.document
                this.comodos = params.beneficiado.comodos
                this.conjuge = params.beneficiado.conjuge
                this.observacao = params.beneficiado.observacao
                this.token = params.beneficiado.token
                this.status = params.beneficiado.status
            }
        })
        
    },
    methods: {
        async validate () {
            if (!this.$refs.form.validate()) {
                this.error = 'Informe os dados solicitados'
                // this.dialogBeneficiadoForm = false
                return
            }
            this.loading = true

            // Se for edição
            if(this.beneficiado && this.beneficiado.id) {
                // Caso contrario
                const update_beneficiado = {
                    updated_at: new Date(),
                    situacao: this.situacao,
                    name: this.name,
                    phone: formatPhone(this.phone),
                    filhosAte10: this.filhosAte10,
                    filhosMaior10: this.filhosMaior10,
                    document: this.document,
                    bairro: this.bairro,
                    localidade: this.localidade,
                    aluguel: this.aluguel,
                    valorAluguel: this.valorAluguel,
                    comodos: this.comodos,
                    conjuge: this.conjuge,
                    observacao: this.observacao,
                    isWhatsApp: this.isWhatsApp,
                    type: 'beneficiado',
                    token: this.token,
                    status: this.status,
                }
                updateBeneficiado(this.beneficiado.id, update_beneficiado)
                .then(() => {
                    this.$store.commit('setBeneficiado', { ...this.beneficiado, ...update_beneficiado})
                    this.showSnackBar('Cadastro atualizado com sucesso. ', 'green')
                    this.dialogBeneficiadoForm = false
                    this.loading = false
                })
                .catch(err => {
                    console.error(err)
                    this.error = 'Erro ao realizar o cadastro'
                    this.showSnackBar('Erro ao realizar o cadastro', 'red')
                    this.loading = false
                })
            } else {
                // Caso contrario
                const new_beneficiado = {
                    created_at: new Date(),
                    updated_at: new Date(),
                    status: 'ativo',
                    name: this.name,
                    phone: formatPhone(this.phone),
                    isWhatsApp: this.isWhatsApp,
                    type: 'beneficiado',
                    token: this.token,
                    document: this.document,

                    situacao: this.situacao,
                    filhosAte10: this.filhosAte10,
                    filhosMaior10: this.filhosMaior10,
                    bairro: this.bairro,
                    localidade: this.localidade,
                    aluguel: this.aluguel,
                    valorAluguel: this.valorAluguel,
                    comodos: this.comodos,
                    conjuge: this.conjuge,
                    observacao: this.observacao,
                }
                setBeneficiado(new_beneficiado)
                    .then(() => {
                        this.$store.commit('setBeneficiado', new_beneficiado)
                        this.showSnackBar('Cadastro realizado com sucesso. ', 'green')
                        this.dialogBeneficiadoForm = false
                        this.loading = false
                    })
                    .catch(err => {
                        console.error(err)
                        this.error = 'Erro ao realizar o cadastro'
                        this.showSnackBar('Erro ao realizar o cadastro', 'red')
                        this.loading = false
                    })
            }
        },
        showSnackBar(text, color) {
            this.snackbarText = text,
            this.snackbarColor = color
            this.snackbar = true
        },
        clear() {
            this.type = 'new'
            this.name = ''
            this.phone = ''
            this.qtdeFilhos = ''
            this.isWhatsApp = true
            this.error = null
            this.token = customAlphabet('1234567890', 4)(),
            this.beneficiado = null
            this.id = null
            this.valid = true
            this.status = 'ativo'
            this.aluguel = null
            this.situacao = null
            this.filhosAte10 = 0
            this.filhosMaior10 = 0
            this.document = ''
            this.bairro = ''
            this.localidade = ''
            this.valorAluguel = null
            this.comodos = null
            this.conjuge = null
            this.observacao = null
            this.type = 'beneficiado'
        },
        clearError() {
            this.error = ''
        }
    }
}
</script>