<template>
    <v-container fluid>
        <v-row class="pb-4">
            <v-col cols="12" sm="4" lg="4">
                <v-card
                    class="mx-auto pb-4"
                    color="orange" 
                    dark
                >
                    <v-row>
                        <v-col
                            class="text-h2"
                            cols="8"
                        >
                            <v-list-item two-line>
                                <v-list-item-icon>
                                    <v-icon>mdi-account-child</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title class="text-h5">
                                        Crianças
                                    </v-list-item-title>
                                    <v-list-item-subtitle>Sorteadas</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-col>
                        <v-col
                            class="text-h4"
                            cols="4"
                        >
                            {{ totalSonsDrawed }}
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
            <v-col cols="12" sm="4" lg="4">
                <v-card
                    class="mx-auto pb-4"
                    color="red" 
                    dark
                >
                    <v-row>
                        <v-col
                            class="text-h2"
                            cols="8"
                        >
                            <v-list-item two-line>
                                <v-list-item-icon>
                                    <v-icon>mdi-mother-heart</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title class="text-h5">
                                        Crianças
                                    </v-list-item-title>
                                    <v-list-item-subtitle>Cadastradas</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-col>
                        <v-col
                            class="text-h4"
                            cols="4"
                        >
                            {{ totalChildren }}
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
            <v-col cols="12" sm="4" lg="4">
                <v-card
                    class="mx-auto pb-4"
                    color="green" 
                    dark
                >
                    <v-row>
                        <v-col
                            class="text-h2"
                            cols="8"
                        >
                            <v-list-item two-line>
                                <v-list-item-icon>
                                    <v-icon>mdi-human-female-boy</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title class="text-h5">
                                        Crianças
                                    </v-list-item-title>
                                    <v-list-item-subtitle>Finalizadas</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-col>
                        <v-col
                            class="text-h4"
                            cols="4"
                        >
                            {{ totalChildrenFinished }}
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
            
        </v-row>
        <v-card>
            <v-card-title>
                <v-row class="pa-2" align="center">
                    Crianças
                    <!-- <v-btn
                        class="mx-2"
                        fab
                        small
                        dark
                        color="indigo"
                        @click="insertChildren(null, null, null)"
                        >
                        <v-icon dark small>
                            mdi-plus
                        </v-icon>
                    </v-btn> -->
                    <v-spacer></v-spacer>
                    <v-combobox
                        v-model="status"
                        :items="['cadastro', 'analise', 'aprovado', 'desativado']"
                        label="Status"
                        multiple
                        single-line
                        hide-details
                        chips
                        @change="getChildrenByStatus(childrenList, status)"
                    ></v-combobox>
                    <v-spacer></v-spacer>
                    <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Buscar"
                        single-line
                        hide-details
                        clearable
                    ></v-text-field>
                    <v-menu
                        open-on-hover
                        top
                        offset-y
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                            color="indigo"
                            dark
                            v-bind="attrs"
                            v-on="on"
                            icon
                            >
                            <v-icon large>mdi-microsoft-excel</v-icon>
                            </v-btn>
                        </template>

                        <v-list>
                            <v-list-item link>
                                <download-excel
                                    class="btn btn-default"
                                    :data="childrenList"
                                    :fields="json_fields"
                                    worksheet="criancas"
                                    :name="'criancas_geral_' + new Date().getTime() + '.xls'"
                                >
                                    <v-list-item-title link>Todas</v-list-item-title>
                                </download-excel>
                            </v-list-item>
                            <v-list-item link>
                                <download-excel
                                    class="btn btn-default"
                                    :data="childrenList.filter((el) => el.status === 'aprovado')"
                                    :fields="json_fields"
                                    worksheet="criancas"
                                    :name="'criancas_aprovadas_' + new Date().getTime() + '.xls'"
                                >
                                <v-list-item-title>Apenas Aprovadas</v-list-item-title>
                                </download-excel>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </v-row>
            </v-card-title>
            <v-data-table
                dense
                :headers="headers"
                :items="childrenDataList"
                :search="search"
                :loading="loading"
                loading-text="Buscando crianças... Por favor, aguarde!"
            >
                <template v-slot:item.mother_name="{ item }">
                    <div :class="item.status === 'desativado' ? 'red--text' : ''">
                        {{ item.mother_name }}
                        <v-btn icon v-if="item.mother.isWhatsApp" color="green" :href="'https://api.whatsapp.com/send?phone=' + item.mother.phone" target="_blank"><v-icon right color="green">mdi-whatsapp</v-icon></v-btn>
                        <v-btn icon v-else-if="!item.mother.isWhatsApp" color="green" :href="'tel:+' + item.mother.phone" ><v-icon right color="green">mdi-phone</v-icon></v-btn>
                    </div>
                </template>
                <template v-slot:item.name="{ item }">
                    <div :class="item.status === 'desativado' ? 'red--text' : ''">
                        {{ item.name }}
                    </div>
                </template>
                
                <template v-slot:item.age="{ item }">
                    {{ item.birthday ? calculaIdade(item.birthday) : '' }}
                </template>

                <template v-slot:item.mother="{ item }">
                    {{ item.mother.phone }}
                    <v-btn icon color="green" :href="'https://api.whatsapp.com/send?phone=' + item.mother.phone + '&text=' + setWhatsAppMessage(item.mother.id)" target="_blank"><v-icon right v-if="item.isWhatsApp" color="green">mdi-whatsapp</v-icon></v-btn>
                </template>
                <template v-slot:item.status="{ item }">
                    {{ item.status }}
                    <v-progress-circular
                        indeterminate
                        color="grey"
                        v-if="loading_drawn === item.id"
                        size="25"
                    ></v-progress-circular>
                </template>
                <template v-slot:item.actions="{ item }">
                    <v-icon
                        small
                        class="mr-2"
                        @click="editItem(item.mother_id, null, item)"
                    >
                        mdi-pencil
                    </v-icon>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon :href="'/c/' + item.id" target="_blank">
                                <v-icon
                                    small
                                    v-bind="attrs"
                                    v-on="on"
                                    color="grey"
                                >
                                    mdi-file-document-outline
                                </v-icon>
                            </v-btn>
                        </template>
                        <span>Cartinha</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                small
                                @click="deleteItem(item, item.status === 'desativado' ? item.status_old : 'desativado')"
                                :color="item.status === 'desativado' ? 'red' : 'green'"
                                v-bind="attrs"
                                v-on="on"
                            >
                                mdi-delete
                            </v-icon>
                        </template>
                        <span>{{item.status === 'desativado' ? 'Ativar' : 'Desativar'}}</span>
                    </v-tooltip>
                    <v-tooltip bottom v-if="item.drawn">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon :href="'/cadastro/filhos/'+ item.id" target="_blank">
                                <v-icon
                                    small
                                    v-bind="attrs"
                                    v-on="on"
                                    color="grey"
                                >
                                    mdi-human-male-child
                                </v-icon>
                            </v-btn>
                        </template>
                        <span>Abrir tela de cadastro dos filhos</span>
                    </v-tooltip>
                </template>
                <template v-slot:no-data>
                </template>
            </v-data-table>
        </v-card>
        <mother-form/>
        <v-row justify="center">
            <v-dialog
                v-model="dialog_drawn"
                persistent
                max-width="290"
            >
            <v-card>
                <v-card-title class="text-h5">
                Sorteio
                </v-card-title>
                <v-card-text>Mar.</v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="green darken-1"
                    text
                    @click="dialog_drawn = false"
                >
                    Disagree
                </v-btn>
                <v-btn
                    color="green darken-1"
                    text
                    @click="dialog_drawn = false"
                >
                    Agree
                </v-btn>
                </v-card-actions>
            </v-card>
            </v-dialog>
        </v-row>
        <subscribe-children-form/>
        <v-snackbar
            v-model="snackbar"
            :multi-line="snackbarMultiLine"
            :color="snackbarColor"
            timeout=6000
            :top="true"
            >
            {{ snackbarText }}

            <template v-slot:action="{ attrs }">
                <v-btn
                text
                v-bind="attrs"
                @click="snackbar = false"
                >
                <v-icon dark>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>
    </v-container>
</template>
<script>
  import { updChild } from '../../services/children'
  import { mapActions, mapGetters, mapState } from 'vuex'
  import MotherForm from '../../components/admin/MotherForm.vue'
  import { bus } from '../../main';
  import SubscribeChildrenForm from '../../components/children/SubscribeChildrenForm.vue';
  
  export default {
    data () {
      return {
        search: '',
        headers: [
          {
            text: 'Nome da Mãe',
            align: 'start',
            sortable: true,
            value: 'mother_name',
          },
          { text: 'Nome', value: 'name' },
          { text: 'Idade', value: 'age' },
          { text: 'Roupa', value: 'clothes' },
          { text: 'Calçado', value: 'shoes' },
          { text: 'Status', value: 'status' },
          { text: 'Sorteado', value: 'mother.status' },
        //   { text: 'Sorteado', value: 'prize'},
          { text: 'Ações', value: 'actions' },
        ],
        childrenDataList: [],
        dialog: false,
        dialog_drawn: false,
        snackbar: false,
        snackbarColor: 'green',
        snackbarText: '',
        snackbarMultiLine: true,
        loading_drawn: false,
        loading_delete: false,
        json_fields: {
            "ID": "id",
            "Nome da Mae": "mother_name",
            "Telefone": "mother.phone",
            "local": "mother.pickUpPlace",
            "Nome da Crianca": "name",
            "Documento": "document",
            "Idade": "age",
            "Data de Nasc": "birthday",
            "Sexo": "gender",
            "No. Roupas": "clothes",
            "No. Calcado": "shoes",
            "Status": "status",
            "Sorteio": "mother.status",
            "Foto Documento": "documentUrl",
            "Foto Crianca": "photoUrl",
            "Controle": "mother.order",
            "URL": {
                field: "code",
                callback: (value) => {
                    return `https://larismael.org/c/${value}`;
                },
            }
        },
        whatsapp_message: '',
        drawned: false,
        status: []
      }
    },
    components: {
        MotherForm,
        SubscribeChildrenForm
    },
    computed: {
        ...mapState(['childrenList', 'selectedYear','loading']),
        ...mapGetters(['totalChildren', 'totalChildrenFinished', 'totalSonsDrawed', 'getDrawnChildren', 'getBagDrawnMothers']),
    },
    mounted() {
        if(!this.childrenList || this.childrenList.length <= 0) {
            // this.loading = true
            this.getChildrenAndMother({yearParty: this.selectedYear})
                .catch((error => {
                    console.error(error)
                    // this.loading = false
                }))
        } else {
            this.childrenDataList = this.childrenList
        }
    },
    watch: {
        childrenList() {
            this.childrenDataList = this.childrenList
        }
    },
    methods: {
        ...mapActions(['getChildrenAndMother']),
        // insertChildren(mother_id, index, child) {
        //     let params = {
        //         mother_id: mother_id,
        //         index: index,
        //         dialog: true
        //     }
        //     if(child) params.child = child
        //     bus.$emit('openChildrenDialog', params)
        // },
        editItem(mother_id, index, child) {
            let params = {
                mother_id: mother_id,
                index: index,
                dialog: true,
                isAdmin: true
            }
            if(child) params.child = child
            bus.$emit('openChildrenDialog', params)
            
        },
        deleteItem(item, status) {
            this.loading_delete = item.id
            let data = {
                status: status,
                status_old: item.status
            }
            updChild(item.id, data)
                .then(() => {
                    this.showSnackBar('Cadastro desabilitado com sucesso', 'green')
                    this.loading_delete = false
                })
                .catch((err) => {
                    console.error(err)
                    this.showSnackBar('Ops! Erro ao desabilitar o cadastro', 'red')
                    this.loading_delete = false
                })
        },
        showSnackBar(text, color) {
            this.snackbarText = text,
            this.snackbarColor = color
            this.snackbar = true
        },
        getChildrenByStatus(list, filter) {
            if(!filter || filter.length <= 0) return ['cadastro']
            if(list) {
                let newList = list.filter((el) => {
                    return filter.indexOf(el.status) > -1
                })
                this.childrenDataList = newList
            } else return this.childrenDataList = list
        },
        calculaIdade(dataNasc) {
            var dataAtual = new Date();
            var anoAtual = dataAtual.getFullYear();
            var anoNascParts = dataNasc.split('/');
            var diaNasc =anoNascParts[0];
            var mesNasc =anoNascParts[1];
            var anoNasc =anoNascParts[2];
            var idade = anoAtual - anoNasc;
            var mesAtual = dataAtual.getMonth() + 1; 
            //Se mes atual for menor que o nascimento, nao fez aniversario ainda;  
            if(mesAtual < mesNasc){
                idade--; 
            } else {
            //Se estiver no mes do nascimento, verificar o dia
                if(mesAtual == mesNasc){ 
                    if(new Date().getDate() < diaNasc ){ 
                        //Se a data atual for menor que o dia de nascimento ele ainda nao fez aniversario
                        idade--; 
                    }
                }
            }
            return idade; 
        }
    },
  }
</script>