<template>
    <v-container fluid>
        <v-card>
            <v-card-title>
                Colaboradores
                <v-btn
                    class="mx-2"
                    fab
                    small
                    dark
                    color="indigo"
                    @click="insertUser"
                    >
                    <v-icon dark small>
                        mdi-plus
                    </v-icon>
                </v-btn>
                <v-spacer></v-spacer>
                <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Buscar"
                    single-line
                    hide-details
                    clearable
                ></v-text-field>
            </v-card-title>
            <v-data-table
                v-if="users && users.length > 0"
                :headers="headers"
                :items="users"
                :search="search"
                :loading="loading"
                loading-text="Buscando usuários... Por favor, aguarde!"
            >
                <template v-slot:item.phone="{ item }">
                    {{ item.phone }}
                    <v-btn icon color="green" :href="'https://api.whatsapp.com/send?phone=' + item.phone + '&text=Ol%C3%A1'" target="_blank"><v-icon right v-if="item.isWhatsApp" color="green">mdi-whatsapp</v-icon></v-btn>
                </template>
                <template v-slot:item.group="{ item }">
                    <v-chip v-for="(group, i) in item.group" :key="i" class="ma-1">
                        {{ group }}
                    </v-chip>
                </template>
                <template v-slot:item.status="{ item }">
                    {{ item.status }}
                </template>
                <template v-slot:item.actions="{ item }">
                    <v-icon
                        small
                        class="mr-2"
                        @click="editItem(item)"
                    >
                        mdi-pencil
                    </v-icon>
                    <v-icon
                        small
                        @click="deleteItem(item)"
                    >
                        mdi-delete
                    </v-icon>
                    </template>
                    <template v-slot:no-data>
                </template>
            </v-data-table>
        </v-card>
        <user-form/>
        <v-snackbar
            v-model="snackbar"
            :multi-line="snackbarMultiLine"
            :color="snackbarColor"
            timeout=6000
            :top="true"
            >
            {{ snackbarText }}

            <template v-slot:action="{ attrs }">
                <v-btn
                text
                v-bind="attrs"
                @click="snackbar = false"
                >
                <v-icon dark>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>
    </v-container>
</template>
<script>
  import { updateUser } from '../../services/users'
  import { mapActions, mapState } from 'vuex'
  import UserForm from '../../components/admin/UserForm.vue'
  import { bus } from '../../main';
  export default {
    data () {
      return {
        search: '',
        headers: [
          {
            text: 'Nome',
            align: 'start',
            sortable: true,
            value: 'name',
          },
          { text: 'E-mail', value: 'email' },
          { text: 'Telefone', value: 'phone' },
          { text: 'Grupos', value: 'group' },
          { text: 'Status', value: 'status' },
        //   { text: 'Sorteado', value: 'prize'},
          { text: 'Ações', value: 'actions' },
        ],
        motherList: [],
        loading: false,
        dialog: false,
        dialog_drawn: false,
        snackbar: false,
        snackbarColor: 'green',
        snackbarText: '',
        snackbarMultiLine: true,
        loading_drawn: false,
      }
    },
    components: {
        UserForm
    },
    computed: {
        ...mapState(['users']),
    },
    async mounted() {
        this.loading = true
        this.getUsers()
            .then(() => {
                this.loading = false
            })
            .catch((error => {
                console.error(error)
                this.loading = false
            }))
    },
    methods: {
        ...mapActions(['getUsers']),
        getColor (sons) {
            if (sons > 5) return 'red'
            else if (sons > 3) return 'orange'
            else return 'green'
        },
        insertUser() {
            let user = {
                id: null,
                valid: true,
            }
            bus.$emit('openDialogUser', {dialog: true, user: user, type: 'new'})
        },
        editItem(item) {
            bus.$emit('openDialogUser', {dialog: true, user: item, type: 'edit'})
        },
        setSortable(id, drawn) {
            this.loading_drawn = id
            let data = {
                drawn: !drawn,
                status: (!drawn || drawn === false) ? 'sorteada': 'cadastro'
            }
            updateUser(id, data)
                .then(() => {
                    this.showSnackBar('Mãe marcada como sorteada', 'green')
                    this.loading_drawn = false
                })
                .catch((err) => {
                    console.error(err)
                    this.showSnackBar('Ops! Erro ao marcar mãe como sorteada', 'red')
                    this.loading_drawn = false
                })
        },
        showSnackBar(text, color) {
            this.snackbarText = text,
            this.snackbarColor = color
            this.snackbar = true
        },
    },
  }
</script>