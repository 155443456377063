<template>
  <v-card outlined>
    <div v-if="editor" class="pt-0 mt-0" style="border-bottom: 1px solid #efefef">
        <!-- <v-col
        cols="12"
        sm="6"
        class="py-2"
        >
        <p>Multiple</p> -->

        <!-- <v-btn-toggle
            v-model="toggle_multiple"
            multiple
            class="pt-0 mt-0"
        > -->
            <v-btn 
                @click="editor.chain().focus().toggleBold().run()" 
                :class="{ 'is-active': editor.isActive('bold') }"
                icon
                color="grey"
            >
            <v-icon>mdi-format-bold</v-icon>
            </v-btn>

            <v-btn
                icon
                color="grey"
                @click="editor.chain().focus().toggleItalic().run()" :class="{ 'is-active': editor.isActive('italic') }"
            >
            <v-icon>mdi-format-italic</v-icon>
            </v-btn>

            <v-btn
                icon
                color="grey"
                @click="editor.chain().focus().toggleHeading({ level: 1 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }"
            >
            <v-icon>mdi-format-header-1</v-icon>
            </v-btn>

            <v-btn
                icon
                color="grey"
                @click="editor.chain().focus().toggleHeading({ level: 2 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }"
            >
            <v-icon>mdi-format-header-2</v-icon>
            </v-btn>

            <v-btn
                icon
                color="grey"
                @click="editor.chain().focus().toggleHeading({ level: 3 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 3 }) }"
            >
            <v-icon>mdi-format-header-3</v-icon>
            </v-btn>

            <v-btn
                icon
                color="grey"
                @click="editor.chain().focus().toggleHeading({ level: 4 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 4 }) }"
            >
            <v-icon>mdi-format-header-4</v-icon>
            </v-btn>

            <v-btn
                icon
                color="grey"
                @click="editor.chain().focus().toggleHeading({ level: 5 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 5 }) }"
            >
            <v-icon>mdi-format-header-5</v-icon>
            </v-btn>

            <v-btn
                icon
                color="grey"
                @click="editor.chain().focus().toggleHeading({ level: 6 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 6 }) }"
            >
            <v-icon>mdi-format-header-6</v-icon>
            </v-btn>

            <v-btn
                icon
                color="grey"
                @click="editor.chain().focus().setTextAlign('left').run()" :class="{ 'is-active': editor.isActive({ textAlign: 'left' }) }"
            >
            <v-icon>mdi-format-align-left</v-icon>
            </v-btn>

            <v-btn
                icon
                color="grey"
                @click="editor.chain().focus().setTextAlign('center').run()" :class="{ 'is-active': editor.isActive({ textAlign: 'center' }) }"
            >
            <v-icon>mdi-format-align-center</v-icon>
            </v-btn>

            <v-btn
                icon
                color="grey"
                @click="editor.chain().focus().setTextAlign('right').run()" :class="{ 'is-active': editor.isActive({ textAlign: 'right' }) }"
            >
            <v-icon>mdi-format-align-right</v-icon>
            </v-btn>

            <v-btn
                icon
                color="grey"
                @click="editor.chain().focus().setTextAlign('justify').run()" :class="{ 'is-active': editor.isActive({ textAlign: 'justify' }) }"
            >
            <v-icon>mdi-format-align-justify</v-icon>
            </v-btn>

            <!-- <v-btn>
            <v-icon>mdi-format-underline</v-icon>
            </v-btn>

            <v-btn>
            <v-icon>mdi-format-color-fill</v-icon>
            </v-btn> -->
        <!-- </v-btn-toggle> -->
        <!-- </v-col> -->
    </div>
    <editor-content :editor="editor" class="pa-3" />
  </v-card>
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-2'
import StarterKit from '@tiptap/starter-kit'

export default {
  components: {
    EditorContent,
  },

  props: {
    value: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      editor: null,
      toggle_multiple: [0, 1, 2],
    }
  },

  watch: {
    value(value) {
      // HTML
      const isSame = this.editor.getHTML() === value

      // JSON
      // const isSame = this.editor.getJSON().toString() === value.toString()

      if (isSame) {
        return
      }

      this.editor.commands.setContent(this.value, false)
    },
  },

  mounted() {
    this.editor = new Editor({
      extensions: [
        StarterKit,
      ],
      content: this.value,
      onUpdate: () => {
        // HTML
        this.$emit('input', this.editor.getHTML())

        // JSON
        // this.$emit('input', this.editor.getJSON())
      },
    })
  },

  beforeDestroy() {
    this.editor.destroy()
  },
}
</script>
