<template>
    <v-container fluid>
        <v-row class="pb-4">
            <v-col cols="12" sm="3" lg="3">
                <v-card
                    class="mx-auto pb-4"
                    color="red" 
                    dark
                >
                    <v-row>
                        <v-col
                            class="text-h2"
                            cols="8"
                        >
                            <v-list-item two-line>
                                <v-list-item-icon>
                                    <v-icon>mdi-mother-heart</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title class="text-h5">
                                        Mães
                                    </v-list-item-title>
                                    <v-list-item-subtitle>Cadastradas</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-col>
                        <v-col
                            class="text-h4 mt-4"
                            cols="4"
                        >
                            {{ totalMothers }}
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
            <v-col cols="12" sm="3" lg="3">
                <v-card
                    class="mx-auto pb-4"
                    color="blue" 
                    dark
                >
                    <v-row>
                        <v-col
                            class="text-h2"
                            cols="8"
                        >
                            <v-list-item two-line>
                                <v-list-item-icon>
                                    <v-icon>mdi-human-female-boy</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title class="text-h5">
                                        Crianças
                                    </v-list-item-title>
                                    <v-list-item-subtitle>Informadas</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-col>
                        <v-col
                            class="text-h4 mt-4"
                            cols="4"
                        >
                            {{ totalSons }}
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
            <v-col cols="12" sm="3" lg="3">
                <v-card
                    class="mx-auto pb-4"
                    color="purple" 
                    dark
                >
                    <v-row>
                        <v-col
                            class="text-h2"
                            cols="8"
                        >
                            <v-list-item two-line>
                                <v-list-item-icon>
                                    <v-icon>mdi-account-child</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title class="text-h5">
                                        Crianças
                                    </v-list-item-title>
                                    <v-list-item-subtitle>Sorteadas sítio</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-col>
                        <v-col
                            class="text-h4 mt-4"
                            cols="4"
                        >
                            {{ totalSonsDrawed }}
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
            <v-col cols="12" sm="3" lg="3">
                <v-card
                    class="mx-auto pb-4"
                    color="orange" 
                    dark
                >
                    <v-row>
                        <v-col
                            class="text-h2"
                            cols="8"
                        >
                            <v-list-item two-line>
                                <v-list-item-icon>
                                    <v-icon>mdi-account-child</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title class="text-h5">
                                        Crianças
                                    </v-list-item-title>
                                    <v-list-item-subtitle>Sorteadas Sacolinha</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-col>
                        <v-col
                            class="text-h4 mt-4"
                            cols="4"
                        >
                            {{ totalSonsBagDrawed }}
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
        <v-card>
            <v-card-title>
                <v-row class="pa-2" align="center">
                    Mães
                    <v-btn
                        class="mx-2"
                        fab
                        small
                        dark
                        color="indigo"
                        @click="insertMother"
                        >
                        <v-icon dark small>
                            mdi-plus
                        </v-icon>
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-checkbox
                        v-model="drawned"
                        label="Sorteadas sítio"
                        class="mt-2"
                        @change="drawnFilter(drawned)"
                    ></v-checkbox>
                    <v-spacer></v-spacer>
                    <v-checkbox
                        v-model="drawnedbag"
                        label="Sorteadas Sacolinha"
                        class="mt-2"
                        @change="bagDrawnFilter(drawnedbag)"
                    ></v-checkbox>
                    <v-spacer></v-spacer>
                    <v-checkbox
                        v-model="drawnedbasket"
                        label="Cesta Basica"
                        class="mt-2"
                        @change="basketDrawnFilter(drawnedbasket)"
                    ></v-checkbox>
                    <v-spacer></v-spacer>
                    <v-checkbox
                        v-model="drawnedpray"
                        label="Evangelização"
                        class="mt-2"
                        @change="prayDrawnFilter(drawnedpray)"
                    ></v-checkbox>
                    <v-spacer></v-spacer>
                    <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Buscar"
                        single-line
                        hide-details
                        clearable
                    ></v-text-field>
                    <v-menu
                        open-on-hover
                        top
                        offset-y
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                            color="indigo"
                            dark
                            v-bind="attrs"
                            v-on="on"
                            icon
                            >
                            <v-icon large>mdi-microsoft-excel</v-icon>
                            </v-btn>
                        </template>

                        <v-list>
                            <v-list-item link>
                                <download-excel
                                    class="btn btn-default"
                                    :data="mothers"
                                    :fields="json_fields"
                                    worksheet="maes"
                                    :name="'maes_geral_' + new Date().getTime() + '.xls'"
                                >
                                    <v-list-item-title link>Todas</v-list-item-title>
                                </download-excel>
                            </v-list-item>
                            <v-list-item link>
                                <download-excel
                                    class="btn btn-default"
                                    :data="getDrawnMothers"
                                    :fields="json_fields"
                                    worksheet="maes"
                                    :name="'maes_sorteadas_sítio_' + new Date().getTime() + '.xls'"
                                >
                                <v-list-item-title>Sorteadas sítio</v-list-item-title>
                                </download-excel>
                            </v-list-item>
                            <v-list-item link>
                                <download-excel
                                    class="btn btn-default"
                                    :data="getBagDrawnMothers"
                                    :fields="json_fields"
                                    worksheet="maes"
                                    :name="'maes_sorteadas_sacolinhas' + new Date().getTime() + '.xls'"
                                >
                                <v-list-item-title>Sorteadas Sacolinha</v-list-item-title>
                                </download-excel>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </v-row>
            </v-card-title>
            <v-data-table
                dense
                :headers="headers"
                :items="motherList"
                :search="search"
                :loading="loading"
                loading-text="Buscando mães... Por favor, aguarde!"
                :items-per-page="30"
            >
                <template v-slot:item.name="{ item }">
                    <div :class="item.status === 'desativado' ? 'red--text text-decoration-line-through' : ''">
                        {{ item.name }}
                        <v-chip small dark color="red" class="ma-2" v-if="item.status === 'desativado'">{{ item.status }}</v-chip>
                        <v-btn
                            class="mx-2"
                            icon
                            small
                            dark
                            color="grey"
                            @click="insertChild(item.id, null, null)"
                            v-if="(item.drawn || item.drawnBag || item.drawnBasket || item.drawnPray) && item.status !== 'desativado'"
                        >
                            <v-icon dark small>
                                mdi-plus
                            </v-icon>
                        </v-btn>
                    </div>
                </template>
                <template v-slot:item.document="{ item }">
                    <div :class="item.status === 'desativado' ? 'red--text text-decoration-line-through' : ''">
                        {{ item.document }}
                        <!-- <v-btn v-if="item.drawn || item.drawnBag || item.drawnBasket || item.drawnPray" icon color="green" :href="'https://api.whatsapp.com/send?phone=' + item.phone + '&text=' + (item.drawn || item.drawnBag || item.drawnBasket || item.drawnPray ? setWhatsAppMessage(item) : 'Ol%C3%A1')" target="_blank"><v-icon right color="green">mdi-whatsapp</v-icon></v-btn> -->
                        <!-- <v-menu open-on-hover top offset-y>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon v-on="on" v-bind="attrs" right color="green">mdi-whatsapp</v-icon>
                            </template>
                            <v-list>
                              <v-list-item
                                v-for="(message, index) in messages"
                                :key="index"
                                :href="'https://api.whatsapp.com/send?phone=' + item.phone + '&text=' + (message.mensagem ? setWhatsAppMessage(item, message.mensagem) : 'Ol%C3%A1')" target="_blank"
                              >
                                <v-list-item-title>{{ message.title }}</v-list-item-title>
                              </v-list-item>
                            </v-list>
                        </v-menu> -->
                    </div>
                </template>
                <template v-slot:item.sons="{ item }">
                    <v-chip
                        :color="getColor(item.sons, item.numberOfChildren)"
                        dark
                        :class="item.status === 'desativado' ? 'red--text text-decoration-line-through' : ''"
                    >
                        {{ item.numberOfChildren ? item.numberOfChildren : 0 }} de {{ item.sons }}
                    </v-chip>
                </template>
                <template v-slot:item.phone="{ item }">
                    <div :class="item.status === 'desativado' ? 'red--text text-decoration-line-through' : ''">
                        {{ item.phone }}
                        <!-- <v-btn v-if="item.drawn || item.drawnBag" icon color="green" :href="'https://api.whatsapp.com/send?phone=' + item.phone + '&text=' + (item.drawn || item.drawnBag ? setWhatsAppMessage(item) : 'Ol%C3%A1')" target="_blank"><v-icon right color="green">mdi-whatsapp</v-icon></v-btn> -->
                        <v-menu open-on-hover top offset-y>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon v-on="on" v-bind="attrs" right color="green">mdi-whatsapp</v-icon>
                            </template>
                            <v-list>
                              <v-list-item
                                v-for="(message, index) in messages"
                                :key="index"
                                :href="'https://api.whatsapp.com/send?phone=' + item.phone + '&text=' + (message.mensagem ? setWhatsAppMessage(item, message.mensagem) : 'Ol%C3%A1')" target="_blank"
                              >
                                <v-list-item-title><v-icon left color="green">mdi-whatsapp</v-icon>{{ message.title }}</v-list-item-title>
                              </v-list-item>
                            </v-list>
                        </v-menu>
                    </div>
                </template>
                <template v-slot:item.status="{ item }">
                    <v-progress-circular
                        indeterminate
                        color="grey"
                        v-if="loading_drawn === item.id"
                        size="25"
                    ></v-progress-circular>
                    <div v-else>
                        <v-tooltip bottom :color="item.drawn===true || (Array.isArray(item.drawn) && item.drawn.includes(selectedYear)) ? 'red' : 'orange' " dark>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon @click.prevent="setSortable(item.id, item.drawn, selectedYear)">
                                    <v-icon right :color="item.drawn === true || (Array.isArray(item.drawn) && item.drawn.includes(selectedYear)) ? 'green':'grey'" v-bind="attrs" v-on="on">mdi-party-popper</v-icon>
                                </v-btn>
                            </template>
                            <span>{{item.drawn===true || (Array.isArray(item.drawn) && item.drawn.includes(selectedYear)) ? 'Desmarcar como sorteado para o sítio' : 'Marcar como sorteado para o sítio'}}</span>
                        </v-tooltip>
                        <v-tooltip bottom :color="item.drawnBag===true  || (Array.isArray(item.drawnBag) && item.drawnBag.includes(selectedYear)) ? 'red':'orange'" dark>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon @click.prevent="setSortableBag(item.id, item.drawnBag, selectedYear)">
                                    <v-icon right :color="item.drawnBag===true || (Array.isArray(item.drawnBag) && item.drawnBag.includes(selectedYear)) ?'green':'grey'" v-bind="attrs" v-on="on">mdi-gift-outline</v-icon>
                                </v-btn>
                            </template>
                            <span>{{item.drawnBag===true || (Array.isArray(item.drawnBag) && item.drawnBag.includes(selectedYear)) ? 'Desmarcar como sorteado da sacolinha' : 'Marcar como sorteado da sacolinha'}}</span>
                        </v-tooltip>
                        <v-tooltip bottom :color="item.drawnBasket===true?'red':'orange'" dark>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon @click.prevent="setBasket(item.id, item.drawnBasket)">
                                    <v-icon right :color="item.drawnBasket===true?'green':'grey'" v-bind="attrs" v-on="on">mdi-shopping-outline</v-icon>
                                </v-btn>
                            </template>
                            <span>{{item.drawnBasket===true ? 'Desmarcar como cesta basica' : 'Marcar cesta basica'}}</span>
                        </v-tooltip>
                        <v-tooltip bottom :color="item.drawnBag===true?'red':'orange'" dark>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon @click.prevent="setPray(item.id, item.drawnPray)">
                                    <v-icon right :color="item.drawnPray===true?'green':'grey'" v-bind="attrs" v-on="on">mdi-hands-pray</v-icon>
                                </v-btn>
                            </template>
                            <span>{{item.drawnPray===true ? 'Desmarcar como evangelização' : 'Marcar como evangelização'}}</span>
                        </v-tooltip>
                    </div>
                </template>
                
                <template v-slot:item.actions="{ item }">
                    <v-icon
                        small
                        class="mr-2"
                        @click="editItem(item)"
                    >
                        mdi-pencil
                    </v-icon>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                small
                                @click="deleteItem(item, item.status === 'desativado' ? item.status_old : 'desativado')"
                                :color="item.status === 'desativado' ? 'red' : 'green'"
                                v-bind="attrs"
                                v-on="on"
                            >
                                mdi-delete
                            </v-icon>
                        </template>
                        <span>{{item.status === 'desativado' ? 'Ativar' : 'Desativar'}}</span>
                    </v-tooltip>
                    <v-tooltip bottom v-if="item.drawn || item.drawnBag">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon :href="'/cadastro/filhos/'+ item.id" target="_blank">
                                <v-icon
                                    small
                                    v-bind="attrs"
                                    v-on="on"
                                    color="grey"
                                >
                                    mdi-human-male-child
                                </v-icon>
                            </v-btn>
                        </template>
                        <span>Abrir tela de cadastro dos filhos</span>
                    </v-tooltip>
                </template>
                <template v-slot:no-data>
                </template>
            </v-data-table>
        </v-card>
        <mother-form/>
        <v-row justify="center">
            <v-dialog
                v-model="dialog_drawn"
                persistent
                max-width="290"
            >
            <v-card>
                <v-card-title class="text-h5">
                Sorteio
                </v-card-title>
                <v-card-text>Mar.</v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="green darken-1"
                    text
                    @click="dialog_drawn = false"
                >
                    Disagree
                </v-btn>
                <v-btn
                    color="green darken-1"
                    text
                    @click="dialog_drawn = false"
                >
                    Agree
                </v-btn>
                </v-card-actions>
            </v-card>
            </v-dialog>
        </v-row>
        <subscribe-children-form/>
        <v-snackbar
            v-model="snackbar"
            :multi-line="snackbarMultiLine"
            :color="snackbarColor"
            timeout=6000
            :top="true"
            >
            {{ snackbarText }}

            <template v-slot:action="{ attrs }">
                <v-btn
                text
                v-bind="attrs"
                @click="snackbar = false"
                >
                <v-icon dark>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>
    </v-container>
</template>
<script>
  import { updateMother } from '../../services/mothers'
  import { mapActions, mapGetters, mapState } from 'vuex'
  import MotherForm from '../../components/admin/MotherForm.vue'
  import { bus } from '../../main';
  import SubscribeChildrenForm from '../../components/children/SubscribeChildrenForm.vue';
  export default {
    data () {
      return {
        search: '',
        headers: [
          {
            text: 'Nome da Mãe',
            align: 'start',
            sortable: true,
            value: 'name',
          },
          { text: 'Documento', value: 'document' },
          { text: 'No. Filhos', value: 'sons' },
          { text: 'Telefone', value: 'phone' },
          { text: 'Senha', value: 'token' },
          { text: 'Localidade', value: 'pickUpPlace'},
          { text: 'Participação', value: 'status' },
        //   { text: 'Sorteado', value: 'prize'},
          { text: 'Ações', value: 'actions' },
        ],
        motherList: [],
        // loading: false,
        dialog: false,
        dialog_drawn: false,
        snackbar: false,
        snackbarColor: 'green',
        snackbarText: '',
        snackbarMultiLine: true,
        loading_drawn: false,
        json_fields: {
            "Nome da Mae": "name",
            "Documento": "document",
            "Telefone": "phone",
            "No. Filhos": "sons",
            "Senha": "token",
            "Status": "status",
            "WhatsApp?": "isWhatsApp",
            "ID": "id"
        },
        drawned: false,
        drawnedbag: false,
        drawnedbasket: false,
        drawnedpray: false,
        messages: []
      }
    },
    components: {
        MotherForm,
        SubscribeChildrenForm
    },
    computed: {
        ...mapState(['mothers', 'selectedYear', 'config', 'loading']),
        ...mapGetters(['totalMothers', 'totalSons', 'totalSonsCad', 'getDrawnMothers','getBagDrawnMothers','getBasketDrawnMothers','getPrayDrawnMothers', 'totalSonsDrawed', 'totalSonsBagDrawed'])
    },
    async mounted() {
        if(!this.mothers || this.mothers.length <= 0) {
            // this.loading = true
            await this.getMothers({yearParty: this.selectedYear})
                .catch((error => {
                    console.error(error)
                }))
        }
        if(this.mothers) this.motherList = this.mothers
        if(this.config.subscribe) {
            this.messages = [
                { title: 'Data do sorteio', mensagem: this.config.subscribe.welcomeMessage },    
                { title: 'Mães', mensagem: this.config.subscribe.whatsappMessage },
                { title: 'Cesta Basica', mensagem: this.config.subscribe.whatsappMessageCestaBasica },
                { title: 'Confirmar crianças cadastradas', mensagem: this.config.subscribe.whatsappMessageChild },
            ]
        }
    },
    watch: {
        mothers() {
            this.motherList = this.mothers
        }
    },
    methods: {
        ...mapActions(['getMothers']),
        getColor (sons, numberOfChildren) {
            if (!numberOfChildren || numberOfChildren === 0) return 'grey'
            else if (sons > numberOfChildren) return 'orange'
            else if (sons === numberOfChildren) return 'green'
            else return 'red'
        },
        insertMother() {
            let mother = {
                type: 'new',
                name: '',
                phone: '',
                qtdeFilhos: '',
                isWhatsApp: true,
                error: null,
                // token: customAlphabet('1234567890', 4)(),
                mother: null,
                id: null,
                valid: true,
            }
            bus.$emit('openDialog', {dialog: true, mother: mother})
        },
        insertChild(mother_id, index, child) {
            let params = {
                mother_id: mother_id,
                index: index,
                dialog: true
            }
            if(child) params.child = child
            bus.$emit('openChildrenDialog', params)
        },
        editItem(item) {
            bus.$emit('openDialog', {dialog: true, mother: item})
        },
        drawnFilter(drawned) {
            if(drawned) {
                this.motherList = this.getDrawnMothers
            }
            else this.motherList = this.mothers
        },
        bagDrawnFilter(drawned) {
            if(drawned) {
                this.motherList = this.getBagDrawnMothers
            }
            else this.motherList = this.mothers
        },
        basketDrawnFilter(drawned) {
            if(drawned) {
                this.motherList = this.getBasketDrawnMothers
            }
            else this.motherList = this.mothers
        },
        prayDrawnFilter(drawned) {
            if(drawned) {
                this.motherList = this.getPrayDrawnMothers
            }
            else this.motherList = this.mothers
        },
        setSortable(id, drawn, selectedYear) {
            this.loading_drawn = true
            let hasDrawn = false
            let newDrawn = Array.isArray(drawn) ? drawn : []
            if(Array.isArray(drawn) && drawn.includes(selectedYear)) {
                newDrawn = drawn.filter((d) => d !== selectedYear)
                hasDrawn = true
            } else {
                newDrawn.push(selectedYear)
            }
            let data = {
                drawn: newDrawn,
                status: (!hasDrawn || hasDrawn === false) ? 'sorteada sítio': 'cadastro',
                updated_at: new Date()
            }
            updateMother(id, data)
                .then(() => {
                    this.showSnackBar(!hasDrawn ? 'Mãe marcada como sorteada para o sítio' : 'Mãe retirada de sorteada para o sítio', !hasDrawn ? 'green' : 'warning')
                    this.loading_drawn = false
                })
                .catch((err) => {
                    console.error(err)
                    this.showSnackBar('Ops! Erro ao marcar mãe como sorteada para o sítio', 'red')
                    this.loading_drawn = false
                })
        },
        setSortableBag(id, drawn, selectedYear) {
            this.loading_drawn = true
            console.log('selectedYear: ', selectedYear + ' - ' + drawn)
            this.loading_drawn = id
            let hasDrawn = false
            let newDrawn = Array.isArray(drawn) ? drawn : []
            if(Array.isArray(drawn) && drawn.includes(selectedYear)) {
                newDrawn = drawn.filter((d) => d !== selectedYear)
                console.log('d: ', drawn)
                hasDrawn = true
            } else {
                newDrawn.push(selectedYear)
            }
            console.log('drawn: ', newDrawn)
            let data = {
                drawnBag: newDrawn,
                status: (!hasDrawn || hasDrawn === false) ? 'sorteada sacolinha': 'cadastro',
                updated_at: new Date()
            }
            updateMother(id, data)
                .then(() => {
                    this.showSnackBar(!hasDrawn ? 'Mãe marcada como sorteada para o sacolinha' : 'Mãe retirada de sorteada para o sacolinha', !hasDrawn ? 'green' : 'warning')
                    this.loading_drawn = false
                })
                .catch((err) => {
                    console.error(err)
                    this.showSnackBar('Ops! Erro ao marcar mãe como sorteada  para Sacolinha', 'red')
                    this.loading_drawn = false
                })
        },
        setBasket(id, drawn) {
            this.loading_drawn = id
            let data = {
                drawnBasket: !drawn,
                status: (!drawn || drawn === false) ? 'cesta basica': 'cadastro',
                updated_at: new Date()
            }
            updateMother(id, data)
                .then(() => {
                    this.showSnackBar('Mãe marcada para receber cesta basica', 'green')
                    this.loading_drawn = false
                })
                .catch((err) => {
                    console.error(err)
                    this.showSnackBar('Ops! Erro ao marcar mãe para receber cesta basica', 'red')
                    this.loading_drawn = false
                })
        },
        setPray(id, drawn) {
            this.loading_drawn = id
            let data = {
                drawnPray: !drawn,
                status: (!drawn || drawn === false) ? 'evangelização': 'cadastro',
                updated_at: new Date()
            }
            updateMother(id, data)
                .then(() => {
                    this.showSnackBar('Mãe marcada para evangelização', 'green')
                    this.loading_drawn = false
                })
                .catch((err) => {
                    console.error(err)
                    this.showSnackBar('Ops! Erro ao marcar mãe para evangelização', 'red')
                    this.loading_drawn = false
                })
        },
        deleteItem(item, status) {
            this.loading_delete = item.id
            let data = {
                status: status,
                status_old: item.status,
                updated_at: new Date()
            }
            updateMother(item.id, data)
                .then(() => {
                    this.showSnackBar('Cadastro desabilitado com sucesso', 'green')
                    this.loading_delete = false
                })
                .catch((err) => {
                    console.error(err)
                    this.showSnackBar('Ops! Erro ao desabilitar o cadastro', 'red')
                    this.loading_delete = false
                })
        },
        showSnackBar(text, color) {
            this.snackbarText = text,
            this.snackbarColor = color
            this.snackbar = true
        },
        setWhatsAppMessage(mother, mensagem) {
            // let firstname = mother.name.split(' ')[0]
            // firstname = firstname.charAt(0).toUpperCase() + firstname.slice(1);
            let firstname = mother.name
            // let msg = 'Ol%C3%A1%20'+firstname+'%2C%20sou%20do%20Lar%20Ismael%2C%20suas%20crian%C3%A7as%20foram%20sorteadas%20para%20participar%20da%2025%C2%AA%20Festa%20de%20Natal%20do%20Lar%20Ismael%20'+(mother.drawnBag ? '*para%20receber%20a%20sacola%20de%20Natal%20dia%2017%20de%20dezembro*.' : 'no%20*s%C3%ADtio*.')+'%0A%0AVc%20vai%20precisar%20entrar%20no%20link%20abaixo%20para%20cadastrar%20o(s)%20dado(s)%20da(s)%20sua(s)%20crian%C3%A7as(s)%2C%20at%C3%A9%20dia%2024%20de%20agosto.%0Ahttps%3A%2F%2Flarismael.org%2Fcadastro%2Ffilhos%2F'+mother.id+'%0A%0A*No%20v%C3%ADdeo%20tem%20tudo%20explicadinho%20como%20voc%C3%AA%20tem%20que%20fazer%2C%20qualquer%20d%C3%BAvida%20fale%20com%20a%20gente%20*respondendo%20a%20essa%20mensagem*.%0A%0AN%C3%B3s%20do%20Lar%20Ismael%2C%20vamos%20conferir%20os%20documentos%20das%20crian%C3%A7as%2C%20%C3%A9%20muito%20importante%20que%20a%20foto%20esteja%20leg%C3%ADvel.%0A%0AQuando%20terminarmos%20de%20conferir%20os%20dados%20da%20sua%20fam%C3%ADlia%2C%20voc%C3%AA%20vai%20receber%20mais%20uma%20mensagem%20dizendo%20que%20seu%20cadastro%20est%C3%A1%20ok.%20%0A%0A*Estamos%20muito%20felizes%20de%20ter%20voc%C3%AAs%20com%20a%20gente%20neste%20ano!!!%20Que%20Deus%20aben%C3%A7oe%20a%20todos!*'
            let msg = mensagem || 'Ol%C3%A1%20'+firstname+'%2C%20sou%20do%20Lar%20Ismael%2C%20suas%20crian%C3%A7as%20foram%20sorteadas%20para%20participar%20da%2025%C2%AA%20Festa%20de%20Natal%20do%20Lar%20Ismael%20'+(mother.drawnBag ? '*para%20receber%20a%20sacola%20de%20Natal%20dia%2017%20de%20dezembro*.' : 'no%20*s%C3%ADtio*.')+'%0A%0AVc%20vai%20precisar%20entrar%20no%20link%20abaixo%20para%20cadastrar%20o(s)%20dado(s)%20da(s)%20sua(s)%20crian%C3%A7as(s)%2C%20at%C3%A9%20dia%2024%20de%20agosto.%0Ahttps%3A%2F%2Flarismael.org%2Fcadastro%2Ffilhos%2F'+mother.id+'%0A%0A*No%20v%C3%ADdeo%20tem%20tudo%20explicadinho%20como%20voc%C3%AA%20tem%20que%20fazer%2C%20qualquer%20d%C3%BAvida%20fale%20com%20a%20gente%20*respondendo%20a%20essa%20mensagem*.%0A%0AN%C3%B3s%20do%20Lar%20Ismael%2C%20vamos%20conferir%20os%20documentos%20das%20crian%C3%A7as%2C%20%C3%A9%20muito%20importante%20que%20a%20foto%20esteja%20leg%C3%ADvel.%0A%0AQuando%20terminarmos%20de%20conferir%20os%20dados%20da%20sua%20fam%C3%ADlia%2C%20voc%C3%AA%20vai%20receber%20mais%20uma%20mensagem%20dizendo%20que%20seu%20cadastro%20est%C3%A1%20ok.%20%0A%0A*Estamos%20muito%20felizes%20de%20ter%20voc%C3%AAs%20com%20a%20gente%20neste%20ano!!!%20Que%20Deus%20aben%C3%A7oe%20a%20todos!*'
            
            msg = this.formatWhatAppMessage(msg, mother, 'mother')
            return msg
        },
        formatWhatAppMessage(message, mother, obj) {
            let variables = this.config.subscribe.variables || []
            const array = [];
            Object.keys(variables).forEach((key) => {
                array.push({
                    var: key,
                    ref: variables[key]
                })
            });
            array.find((v) => {
                let vobj = v.ref.split('.')[0]
                if(vobj === obj) {
                    message = message.replace('%7B%7B' + v.var + '%7D%7D', eval(v.ref))
                }
            })
            
            return message
        }

    },
  }
</script>